import React from "react";
import {Utils} from "../../../helper/Utils";


interface Props {
    value: number;
}

export const Kilometers: React.FC<Props> = (props) => {
    const n = Utils.precisionRound(props.value/1000, 3).toFixed(3);

    return <>
        {n}km
    </>
}