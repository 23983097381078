import * as React from "react";


interface Props extends React.HTMLAttributes<HTMLDivElement> {
    size?: number;
    offset?: number;
}

export const DataTableCell: React.FC<Props> = ({
                                                   size,
                                                   className = '',
                                                   ...rest
                                               }) => {

    const classNamesMap = {
        1: "w-1/12",
        2: "w-2/12",
        3: "w-3/12",
        4: "w-4/12",
        5: "w-5/12",
        6: "w-6/12",
        7: "w-7/12",
        8: "w-8/12",
        9: "w-9/12",
        10: "w-10/12",
        11: "w-11/12",
        12: "w-12/12",
    }[size as number] || "w-auto";

    return <td className={`${classNamesMap} ${className} p-3 px-4`} {...rest} />
}
