import {TypeId} from "../types/TypeId";
import {User} from "./User";
import {Vehicle} from "./Vehicle";


export enum ExpensesCategory {
    Fuel = "FUEL",
    Oil = "OIL",
    Service = "SERVICE",
    Tires = "TIRES",
    Other = "OTHER",
}

export interface Expenses {
    id: TypeId;
    category: ExpensesCategory;
    amount: number;
    date: string;
    driver_id: TypeId;
    vehicle_id: TypeId;
    description: string;
}

export interface ExpensesWithDriverAndVehicle extends Expenses {
    driver: User;
    vehicle: Vehicle;
}