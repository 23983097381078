import React, {useLayoutEffect} from 'react';
import './App.css';
import {TOKEN_KEY, useAuth} from "./hooks/useAuth";
import {Router} from "./components/Router";

function App() {

    const authState = useAuth();
    useLayoutEffect(() => {
        if (localStorage.getItem(TOKEN_KEY)) {
            authState.setToken(localStorage.getItem(TOKEN_KEY));
        }
        authState.loadUser();
    }, []);

    return <div className="font-sans antialiased">
        {!authState.loading && <Router/>}
    </div>
}

export default App;
