import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {TypeId} from "../types/TypeId";
import {Taximeter, TaximeterWithPosition} from "../models/Taximeter";
import {ServiceMethodOptions} from "../helper/PagingController";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {PaginatedResponse} from "../types/PaginatedResponse";
import {Position} from "../models/Position";

interface GetAllQueryOptions {
    search?: string;
}

export class TaximeterService extends BaseService {

    public async getAll(options: ServiceMethodOptions, queryOptions: GetAllQueryOptions) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
            ...queryOptions,
        })

        return this.response<PaginatedResponse<Taximeter>>(
            client.get(this.BASE_URL + "/taximeters?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

    public async get(id: TypeId) {
        const client = BearerApiClient();

        return this.response<Taximeter>(
            client.get(this.BASE_URL + `/taximeters/${id}`)
        )
    }

    public async create(payload: Partial<Taximeter>) {
        const client = BearerApiClient();

        return this.response<Taximeter>(
            client.post(this.BASE_URL + `/taximeters`, payload)
        )
    }

    public async update(id: TypeId, payload: Partial<Taximeter>) {
        const client = BearerApiClient();

        return this.response<Taximeter>(
            client.put(this.BASE_URL + `/taximeters/${id}`, payload)
        )
    }

    public async withPositions() {
        const client = BearerApiClient();

        return this.response<TaximeterWithPosition[]>(
            client.get(this.BASE_URL + `/taximeters/positions`)
        )
    }

}