import TypeRequestManager from "./TypeRequestManager";
import Subscribable from "./Subscribable";
import {PaginatedResponse} from "../types/PaginatedResponse";

export interface ServiceMethodOptions {
    page: number;
    perPage: number;
    abortSignal?: AbortSignal
}

export default class PagingController<T> extends Subscribable {
    private entriesPerPage;
    private current_page = 0;
    private last_page = 0;

    private activeTimer = false;
    private refreshTimeout = 15;

    private requestManager = new TypeRequestManager();

    data : T[] = [];

    onPageChange = (page: number) => this._onPageChange(page);

    public loading = false;

    constructor(entriesPerPage: number = 25) {
        super();

        this.entriesPerPage = entriesPerPage;
    }

    get page() : number {
        return this.current_page;
    }

    get pageCount() : number {
        return this.last_page;
    }

    private _onPageChange(page: number) {
        this.current_page = page;
        this.notify();
        this.fetch();
    }

    serviceMethod(options: ServiceMethodOptions) : Promise<PaginatedResponse<T>> {
        throw Error("Error in Paging Controller. Service Method not implemented");
    }

    async fetch() {
        this.loading = true;
        this.notify();
        try {
            let id = this.requestManager.request();
            this.requestManager.abort();
            let result = await this.serviceMethod({
                page: this.current_page+1,
                perPage: this.entriesPerPage,
                abortSignal: this.requestManager.signal,
            });

            if(this.requestManager.isValid(id)) {
                this.last_page = result.last_page;
                this.data = result.data;
                this.loading = false;
                this.notify();
            }

        } catch (e: any) {
            if(e.name === "AbortError") {
                return;
            }

            this.loading = false;
            this.notify();
        }

    }

    reset() {
        this.current_page = 0;
        this.last_page = 0;
        this.notify();
    }

    resetAndFetch() {
        this.reset();
        this.fetch();
    }

    useAutoRefresh(timeout: number = 15) {
        this.activeTimer = true;
        this.refreshTimeout = timeout;
        this.refreshFromTimer();
    }

    refreshFromTimer() {
        if(this.activeTimer) {
            if(this.page === 0) {
                this.fetch();
            }
            setTimeout(() => {
                this.refreshFromTimer()
            }, this.refreshTimeout * 1000)
        }
    }

    unmount() {
        this.activeTimer = false;
    }


}