import React from "react";
import {INotification, useNotificationState} from "../../hooks/useNotificationState";
import {CheckCircleIcon, XCircleIcon} from "@heroicons/react/outline";

const NotificationComponent: React.FC<{notification: INotification}> = (props) => {
    const {notification} = props;

    return <div className={"bg-white p-3 border border-gray-200 rounded-md shadow-md"} style={{width: 300}}>
        <div className="flex space-x-2">
            <div className={"shrink-0"}>
                {notification.type === "success" && <CheckCircleIcon className={"w-6 h-6 text-green-500"} />}
                {notification.type === "error" && <XCircleIcon className={"w-6 h-6 text-red-500"} />}
            </div>
            <div className="w-full">
                <div className={"text-black font-medium"}>{notification.title}</div>
                {notification.description && <div className="text-gray-400 text-sm">
                    {notification.description}
                </div>}
            </div>
        </div>
    </div>
}

export const NotificationManager = () => {
    const {notifications} = useNotificationState();

    return <div className={"fixed space-y-3"} style={{bottom: 24, right: 24, zIndex: 100}}>
        {notifications.map(notification => {
            return <NotificationComponent notification={notification} />
        })}
    </div>
};