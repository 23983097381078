import {User} from "../models/User";
import create from "zustand";
import {AuthService} from "../services/AuthService";
import {UserPermissions} from "../models/UserPermissions";

type TOKEN_TYPE = string | null;

export const TOKEN_KEY = "token";

interface AuthState {
    token: TOKEN_TYPE,
    user: User | null,
    setToken: (token: TOKEN_TYPE) => any,
    logout: () => void,
    loadUser: () => any,
    loading: boolean,
    permissions?: UserPermissions;
}

export const useAuth = create<AuthState>((set, get) => ({
    hydrated: false,
    token: null,
    user: null,
    loading: true,
    permissions: undefined,
    setToken: (token) => {
        set(() => ({
            token,
        }));

        if (token) {
            localStorage.setItem(TOKEN_KEY, token);
        } else {
            localStorage.removeItem(TOKEN_KEY);
        }

        get().loadUser();
    },
    logout: () => {
        get().setToken(null);
        set(() => ({
            user: null
        }));
    },
    loadUser: async () => {
        set(() => ({
            loading: true,
        }))

        try {
            const {token} = get();
            if (!token) {
                return null;
            }

            const service = new AuthService();
            try {
                const user = await service.getUser(token);
                set(() => ({user}));
            } catch (e) {
                console.error(e);
                set(() => ({token: null}));
            }

            if (get().user) {
                try {
                    const permissions = await service.getPermissions();
                    set(() => ({permissions}));
                } catch (e) {
                    console.error(e);
                }
            }
        } finally {
            set(() => ({
                loading: false,
            }))
        }
    }
}))