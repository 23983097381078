import {PrimaryButton} from "../ui/Button";
import {Card} from "../ui/Card";
import {Container} from "../ui/Container";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {AuthService} from "../../services/AuthService";
import {useLoading} from "../../hooks/useLoading";
import {GuestGuard} from "./guards/GuestGuard";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {NotificationManager} from "../ui/NotificationManager";
import React from "react";
import {yup} from "../../extension/yup";
import {useParams} from "react-router-dom";
import {useRouter} from "../../hooks/useRouter";

const Schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
    password_confirmation: yup.string().oneOf([yup.ref('password')], 'Passwords must match')
});

export const PasswordResetSetPassword = () => {

    const {token} = useParams<"token">();
    const router = useRouter();
    const {loading, setLoading} = useLoading();

    const passwordSet = async (data: {email: string, password: string}) => {
        setLoading(true);
        try {
            await new AuthService().setNewPassword({
                email: data.email,
                password: data.password,
                password_confirmation: data.password,
                token: token!,
            });
            NotificationInvoker("success", "Erfolgreich", "Passwort erfolgreich zurückgesetzt");
            router.to("/login");
        } catch (e) {
            NotificationInvoker("error", "Fehkgeschlagen", "Bitte überprüfen Sie Ihre Daten");
        }
        setLoading(false);
    }

    return <GuestGuard>
        <div className={"w-screen h-screen bg-gray-100 flex"}>
            <Container size={"md"} className={"my-auto w-full"}>
                <p className={"text-xl font-semibold text-center text-gray-500 mb-6"}>taximeter.ch</p>
                <Card>
                    <Form initialValues={{email: "", password: ""}}
                          validationSchema={Schema}
                          onSubmit={(data) => {
                              passwordSet(data);
                          }}
                    >
                        <div className="space-y-6">
                            <FormGroup name={"email"} title={"E-Mail"} description={"Bitte geben Sie Ihre E-Mail ein"}>
                                <FormInput type="text" name={"email"}/>
                            </FormGroup>
                            <FormGroup name={"password"} title={"Passwort"}>
                                <FormInput type="password" name={"password"}/>
                            </FormGroup>
                            <FormGroup name={"password_confirmation"} title={"Passwort wiederholen"}>
                                <FormInput type="password" name={"password_confirmation"}/>
                            </FormGroup>
                            <PrimaryButton as={"button"} type={"submit"} loading={loading}>Passwort setzen</PrimaryButton>
                        </div>
                    </Form>
                </Card>
            </Container>
        </div>
        <NotificationManager/>
    </GuestGuard>
};