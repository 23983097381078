import React from "react";
import {Currency} from "../../../models/Currency";


interface Props {
    value: number;
    currency: Currency;
}

export const moneyFormat = (currency: Currency, value: number) => {
    return Intl.NumberFormat(navigator.language, {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2
    })
        .format(value / 100);
}

export const Money: React.FC<Props> = (props) => {

    const formatted = moneyFormat(props.currency, props.value);
    return <>
        {formatted}
    </>
}