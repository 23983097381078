import {useEffect, useRef} from "react";
import {useForceUpdate} from "./useForceUpdate";
import PagingController, {ServiceMethodOptions} from "../helper/PagingController";
import {PaginatedResponse} from "../types/PaginatedResponse";


interface PagingControllerProps<T> {
    perPage: number;
    serviceMethod: (options: ServiceMethodOptions) => Promise<PaginatedResponse<T>>
}

export const usePagingController = <T>({perPage, serviceMethod}: PagingControllerProps<T>) => {

    const forceUpdate = useForceUpdate();
    const controllerRef = useRef(
        new PagingController<T>(perPage)
    );

    controllerRef.current.serviceMethod = serviceMethod;

    useEffect(() => {
        controllerRef?.current?.subscribe(() => forceUpdate());
        controllerRef?.current?.fetch();

        return () => {
            controllerRef?.current.unmount();
        }
    }, []);

    return {
        controller: controllerRef.current,
    }
};
