import React, {useEffect, useRef} from "react";
import {AppLayout} from "../layout/AppLayout";
import {useParams} from "react-router-dom";
import {Form} from "../ui/Form";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Card} from "../ui/Card";
import {PrimaryButton} from "../ui/Button";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import {useRouter} from "../../hooks/useRouter";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {yup} from "../../extension/yup";
import {User, UserType} from "../../models/User";
import {UserService} from "../../services/UserService";
import {useAuth} from "../../hooks/useAuth";
import {CompanySelect} from "../ui/async-selects/CompanySelect";
import {FormToggle} from "../ui/Checkbox";
import {CountrySelect} from "../ui/async-selects/CountrySelect";


interface Props {

}

const Schema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    password: yup.string().optional(),
    phone: yup.string().optional(),
    address: yup.string().optional(),
    city: yup.string().optional(),
    country: yup.string().optional(),
    company_id: yup.number().optional(),
    type: yup.string().oneOf(Object.values(UserType)),
});

export const UsersDetail: React.FC<Props> = () => {

    const params = useParams<"id">();
    const router = useRouter();
    const payload = useRef<Partial<User>>({
        type: UserType.company,
    });

    const {permissions} = useAuth();

    const forceUpdate = useForceUpdate();

    const isCreating = params.id === "new";

    const hydrate = async (id: string | undefined) => {
        if (isCreating || !id) {
            return;
        }

        try {
            const _user = await new UserService().get(id);

            payload.current = {
                firstname: _user.firstname,
                lastname: _user.lastname,
                email: _user.email,
                phone: _user.phone,
                address: _user.address,
                city: _user.city,
                country: _user.country,
                company_id: _user.company_id,
                type: _user.type,
            }
            forceUpdate();
        } catch (e) {
            router.to("/users");
        }
    }

    useEffect(() => {
        hydrate(params.id);
    }, [params.id]);

    return <AppLayout title={isCreating
            ? "Benutzer / Neu"
            : `Benutzer / ${params.id}`
        }>
            <Card>
                <Form initialValues={payload.current}
                      enableReinitialize={true}
                      onSubmit={async (data, helpers) => {
                          if(!data.password) {
                              delete data.password;
                          }

                          try {
                              if (!isCreating) {
                                  await new UserService().update(params.id!, data);
                                  NotificationInvoker("success", "Erfolgreich", "Benutzer erfolgreich gespeichert");
                                  router.to("/users");
                              } else {
                                  await new UserService().create(data);
                                  NotificationInvoker("success", "Erfolgreich", "Benutzer erfolgreich gespeichert");
                                  router.to("/users");
                              }
                          } catch (e: any) {
                              e.validation && helpers.setErrors(e.validation);
                              NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                          }
                      }}
                      validationSchema={Schema}
                >
                    <div className="space-y-6">
                        <div className="grid md:grid-cols-2 gap-4">
                            <FormGroup name={"firstname"} title={"Vorname"}>
                                <FormInput name={"firstname"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"lastname"} title={"Nachname"}>
                                <FormInput name={"lastname"} type={"text"} />
                            </FormGroup>
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <FormGroup name={"email"} title={"Email"}>
                                <FormInput name={"email"} type={"email"} />
                            </FormGroup>

                            <FormGroup name={"password"} title={"Passwort"}>
                                <FormInput name={"password"} type={"password"} />
                            </FormGroup>
                        </div>

                        <FormGroup name={"phone"} title={"Telefon"}>
                            <FormInput name={"phone"} type={"text"} />
                        </FormGroup>

                        <div className="grid md:grid-cols-3 gap-4">
                            <FormGroup name={"address"} title={"Addresse"}>
                                <FormInput name={"address"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"city"} title={"Stadt"}>
                                <FormInput name={"city"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"country"} title={"Land"}>
                                <CountrySelect name={"country"} />
                            </FormGroup>
                        </div>

                        {permissions?.users.setCompany && <>
                            <FormGroup name={"company_id"} title={"Unternehmen"}>
                                <CompanySelect name={"company_id"} />
                            </FormGroup>
                        </>}

                        {permissions?.users.setUserType && <>
                            <FormGroup name={"type"} title={"Benutzertyp"}>
                                <div className="flex row space-x-6">
                                    <FormToggle name={"type"} type={"radio"} value={UserType.company}>
                                        Company
                                    </FormToggle>
                                    <FormToggle name={"type"} type={"radio"} value={UserType.admin}>
                                        Admin
                                    </FormToggle>
                                </div>
                            </FormGroup>
                        </>}

                        <PrimaryButton size={"lg"} type={"submit"}>
                            {isCreating && "Erstellen"}
                            {!isCreating && "Speichern"}
                        </PrimaryButton>
                    </div>
                </Form>
            </Card>
        </AppLayout>
};