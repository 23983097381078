import {Formik, FormikConfig, FormikValues} from "formik";
import React from "react";


interface Props<Values extends FormikValues> extends
    Pick<FormikConfig<Values>, "initialValues" | "onSubmit" | "validationSchema" | "enableReinitialize">
{
    children: React.ReactNode | React.ReactNode[]
}


// export declare function Formik<Values extends FormikValues = FormikValues, ExtraProps = {}>(props: FormikConfig<Values> & ExtraProps): JSX.Element;
export function Form<Values extends FormikValues = FormikValues, ExtraProps = {}>(props: Props<Values> & ExtraProps) {

    return <Formik {...props}>
        {formikProps => (
            <form onSubmit={formikProps.handleSubmit}>
                {props.children}
            </form>
        )}
    </Formik>

}