

interface Props {
    plate: string;
}

export const Plate = (props: Props) => {

    if (!props.plate) {
        return null;
    }

    return <span className={"border-2 border-gray-800 text-gray-800 rounded-md font-mono px-2 py-1 text-sm font-semibold"}>
            {props.plate}
    </span>
}