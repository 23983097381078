import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {PaginatedResponse} from "../types/PaginatedResponse";
import {Company} from "../models/Company";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {ServiceMethodOptions} from "../helper/PagingController";
import {TypeId} from "../types/TypeId";


interface GetAllQueryOptions  {
    search?: string;
}

export class CompanyService extends BaseService {

    public async getAll(options: ServiceMethodOptions, queryOptions: GetAllQueryOptions) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
            ...queryOptions,
        })

        return this.response<PaginatedResponse<Company>>(
            client.get(this.BASE_URL + "/companies?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

    public async delete(id: TypeId) {
        const client = BearerApiClient();

        return this.response<Company>(
            client.delete(this.BASE_URL + `/companies/${id}`),
        )
    }

    public async get(id: TypeId) {
        const client = BearerApiClient();

        return this.response<Company>(
            client.get(this.BASE_URL + `/companies/${id}`)
        )
    }

    public async create(payload: Partial<Company>) {
        const client = BearerApiClient();

        return this.response<Company>(
            client.post(this.BASE_URL + `/companies`, payload)
        )
    }

    public async update(id: TypeId, payload: Partial<Company>) {
        const client = BearerApiClient();

        return this.response<Company>(
            client.put(this.BASE_URL + `/companies/${id}`, payload)
        )
    }

}