import React, {useEffect, useRef} from "react";
import {AppLayout} from "../layout/AppLayout";
import {useParams} from "react-router-dom";
import {Form} from "../ui/Form";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Card} from "../ui/Card";
import {PrimaryButton} from "../ui/Button";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import {useRouter} from "../../hooks/useRouter";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {yup} from "../../extension/yup";
import {useAuth} from "../../hooks/useAuth";
import {CompanySelect} from "../ui/async-selects/CompanySelect";
import {Driver} from "../../models/Driver";
import {DriverService} from "../../services/DriverService";


interface Props {

}

const Schema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    company_id: yup.number().optional(),
});

export const DriversDetail: React.FC<Props> = () => {

    const {user, permissions} = useAuth();
    const params = useParams<"id">();
    const router = useRouter();
    const payload = useRef<Partial<Driver>>({
        company_id: user!.company_id,
    });

    const forceUpdate = useForceUpdate();

    const isCreating = params.id === "new";

    const hydrate = async (id: string | undefined) => {
        if (isCreating || !id) {
            return;
        }

        try {
            const _user = await new DriverService().get(id);

            payload.current = {
                firstname: _user.firstname,
                lastname: _user.lastname,
                company_id: _user.company_id,
            }
            forceUpdate();
        } catch (e) {
            router.to("/drivers");
        }
    }

    useEffect(() => {
        hydrate(params.id);
    }, [params.id]);

    return <AppLayout title={isCreating
        ? "Fahrer / New"
        : `Fahrer / ${params.id}`
    }>
        <Card>
            <Form initialValues={payload.current}
                  enableReinitialize={true}
                  onSubmit={async (data, helpers) => {
                      try {
                          if (!isCreating) {
                              await new DriverService().update(params.id!, data);
                              NotificationInvoker("success", "Erfolgreich", "Fahrer erfolgreich gespeichert");
                              router.to("/drivers");
                          } else {
                              await new DriverService().create(data);
                              NotificationInvoker("success", "Erfolgreich", "Fahrer erfolgreich gespeichert");
                              router.to("/drivers");
                          }
                      } catch (e: any) {
                          e.validation && helpers.setErrors(e.validation);
                          NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                      }
                  }}
                  validationSchema={Schema}
            >
                <div className="space-y-6">
                    <div className="grid md:grid-cols-2 gap-4">
                        <FormGroup name={"firstname"} title={"Vorname"}>
                            <FormInput name={"firstname"} type={"text"} />
                        </FormGroup>

                        <FormGroup name={"lastname"} title={"Nachname"}>
                            <FormInput name={"lastname"} type={"text"} />
                        </FormGroup>
                    </div>

                    {permissions?.drivers.setCompany && <>
                        <FormGroup name={"company_id"} title={"Unternehmen"}>
                            <CompanySelect name={"company_id"} />
                        </FormGroup>
                    </>}

                    <PrimaryButton size={"lg"} type={"submit"}>
                        {isCreating && "Erstellen"}
                        {!isCreating && "Speichern"}
                    </PrimaryButton>
                </div>
            </Form>
        </Card>
    </AppLayout>
};