import React from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc);


interface Props {
    value: string | Date;
    format?: DateFormat;
}

export enum DateFormat {
    ISO_DATE = "YYYY-MM-DD",
    DATE = "DD.MM.YYYY",
    TIME = "HH:mm",
    FULL = "DD.MM.YYYY HH:mm",
}

export const FormattedDate: React.FC<Props> = ({
    format = DateFormat.FULL,
    value,
                                               }) => {
    const formatted = dayjs.utc(value).format(format);

    return <>
        {formatted}
    </>
}