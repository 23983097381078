import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {PaginatedResponse} from "../types/PaginatedResponse";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {ServiceMethodOptions} from "../helper/PagingController";
import {Expenses, ExpensesWithDriverAndVehicle} from "../models/Expenses";

export class ExpensesService extends BaseService {

    public async getAll(options: ServiceMethodOptions) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
        })

        return this.response<PaginatedResponse<ExpensesWithDriverAndVehicle>>(
            client.get(this.BASE_URL + "/expenses?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

    public async create(payload: Partial<Expenses>) {
        const client = BearerApiClient();

        return this.response<Expenses>(
            client.post(this.BASE_URL + `/expenses`, payload)
        )
    }

}