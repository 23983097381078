import {SingleOptionWithId} from "../components/ui/AsyncSelect";
import {TSelectOptionValue} from "../components/ui/FormSelect";
import {PaginatedResponse} from "../types/PaginatedResponse";
import {ServiceMethodOptions} from "../helper/PagingController";
import {TypeId} from "../types/TypeId";


interface WithId {
    id: number | TypeId;
}

interface Props<T extends WithId> {
    getAll: (options: ServiceMethodOptions, queryOptions: {search?: string}) => Promise<PaginatedResponse<T>>;
    getById: (id: TypeId) => Promise<T>;
    getLabel: (item: T) => string;

}

export const useAsyncSelect = <T extends WithId>(props: Props<T>) => {

    const getOptions = async (text: string, options: SingleOptionWithId[]) => {
        const perPage = 10;
        const currentPage = Math.ceil(options.length / perPage);
        const response = await props.getAll({
            perPage,
            page: currentPage,
        }, {
            search: text
        });

        return {
            options: response.data.map(company => ({
                label: props.getLabel(company),
                value: company.id,
                data: {
                    ...company,
                }
            })),
            hasMore: response.last_page === currentPage,
        }
    };

    const getInitial = async (value: TSelectOptionValue) => {
        if (!value) {
            return null;
        }

        try {
            const response = await props.getById(value);
            return {
                value: response.id,
                label: props.getLabel(response),
                data: {
                    ...response,
                }
            };
        } catch (e) {
            console.error(e);
        }

        return null;
    }

    return {
        getOptions,
        getInitial,
    }
}