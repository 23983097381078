import React from "react";
import {useField} from "formik";
import DatePicker from "react-datepicker";
import {FormInput} from "./FormInput";

interface BaseProps {
    name: string;
}

export function FormDate(props: BaseProps) {

    const [field, _, helper] = useField(props.name);

    const CustomInput = <FormInput {...field} autoComplete={"off"} />

    return <DatePicker selected={field.value}
                       onBlur={field.onBlur}
                       name={field.name}
                       autoComplete={"off"}
                       onChange={date => helper.setValue(date)}
                       customInput={CustomInput}
    />
}