import React, {useState} from "react";


interface Props {
    children: (opts: {
        toggle: () => void;
        isOpen: boolean;
    }) => React.ReactElement;
}

export const Dropdown: React.FC<Props> = (props) => {
    const [isOpen, setIsOpen] = useState(false);

    return <div className={"relative z-50"}>
        {props.children({
            toggle: () => setIsOpen(!isOpen),
            isOpen,
        })}
    </div>
}

interface ContentProps {

}

export const DropdownContent: React.FC<ContentProps> = (props) => {
    return <>
        <div className="absolute z-50 mt-2 w-48 rounded-md shadow-lg bg-white origin-top-right right-0 border border-gray-100">
            {props.children}
        </div>
    </>
}

export const DropdownItem: React.FC = (props) => {
    return <div className={"block px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition duration-150 ease-in-out"}>
        {props.children}
    </div>
}