import {useLocation, useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import {useMemo} from "react";
import * as queryString from "query-string";

export function useRouter() {
    const params = useParams();
    const location = useLocation();
    const to = useNavigate();
    // Return our custom router object
    // Memoize so that a new object is only returned if something changes
    return useMemo(() => {
        return {
            // For convenience add push(), replace(), pathname at top level
            to: to,
            pathname: location.pathname,
            // Merge params and parsed query string into single "query" object
            // so that they can be used interchangeably.
            // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
            query: {
                ...queryString.parse(location.search),
                ...params,
            },
            location,
        };
    }, [params, location, location]);
}