import axios, {AxiosError, AxiosInstance, AxiosRequestConfig} from "axios";
import {useAuth} from "../hooks/useAuth";

const JsonInterceptor = (req: AxiosRequestConfig) => {
    req.headers ??= {};
    req.headers["Accept"] = "application/json";
    req.headers["Content-Type"] = "application/json";
    return req;
};

declare module "axios" {
    export interface AxiosError {
        validation?: Record<string, string[]>
    }
}

const UnauthorizedInterceptor = (error: AxiosError) => {
    if (error.response?.status === 401) {
        useAuth.getState().logout();
    }
    if (error.response?.status === 422) {
        error.validation = error.response.data.errors;
    }
    throw error;
};

export function BaseApiClient(): AxiosInstance {
    const client = axios.create({});
    client.interceptors.request.use(JsonInterceptor);
    client.interceptors.response.use(undefined, UnauthorizedInterceptor);
    return client;
}

export function BearerApiClient(token = useAuth.getState().token) {
    const client = BaseApiClient();
    client.interceptors.request.use(req => {
        req.headers ??= {};
        req.headers["Authorization"] = "Bearer " + token;
        return req;
    });
    return client;
}