import React from "react";
import {Spinner} from "./SpinnerIcon";
import {PolymorphicComponent} from "../../types/PolymorphicComponent";
import classNames from "classnames";

interface BaseProps {
    size?: "sm" | "md" | "lg",
    className?: string;
    loading?: boolean;
}
type Props<T extends React.ElementType = "button"> = PolymorphicComponent<BaseProps, T>

export function Button<T extends React.ElementType = "button">(props: Props<T>) {

    const {
        size = "sm",
        className = "",
        as: Component = "button",
        children,
        loading,
        ...rest
    } = props;

    return <Component className={[
        classNames({
            "text-center": true,
            "font-semibold uppercase tracking-widest disabled:opacity-25 transition": true,
            "text-sm border transition focus:ring-2 ring-blue-400 ring-offset-2 inline-flex items-center gap-1.5": true,
            "opacity-75 select-none cursor-not-allowed": loading,
            "p-1.5 px-3 rounded-md text-md": size === "sm",
            "p-2 px-4 rounded-md text-md": size === "md",
            "p-3 px-5 rounded-md text-md": size === "lg",
        }),
        className
    ].join(" ")} {...rest}>
        {children}
        {loading && <Spinner />}
    </Component>
}

export function PrimaryButton<T extends React.ElementType = "button">({className = "", ...rest}: Props<T>) {
    const normalClasses = "bg-gray-800 text-white hover:bg-gray-700 active:bg-gray-900 focus:border-gray-900";
    return <Button className={`${normalClasses} ${className}`} {...rest} />
}

export function SecondaryButton<T extends React.ElementType = "button">({className = "", ...rest}: Props<T>) {
    const normalClasses = "bg-white text-gray-400 border-gray-200 shadow-gray-50 shadow-sm";
    const hoverClasses = "hover:bg-gray-100 hover:text-gray-500 hover:border-gray-200 hover:shadow-gray-100";
    return <Button className={`${normalClasses} ${hoverClasses} ${className}`} {...rest} />
}

export function PaginatorButton<T extends React.ElementType = "button">({className = "", isActive = false, ...rest}: Props<T> & {isActive?: boolean}) {
    const normalClasses = classNames({
        "!shadow-none  border-gray-200 text-gray-400 !ring-offset-0 ring-inset": true,
        "bg-white shadow-gray-50": !isActive,
        "hover:bg-gray-100 hover:text-gray-500 hover:border-gray-200 hover:shadow-gray-100": !isActive,
        "bg-gray-100 ": isActive,
    })

    return <Button className={`${normalClasses} ${className}`} {...rest} />
}

export function GhostButton<T extends React.ElementType = "button">({className = "", ...rest}: Props<T>) {
    const normalClasses = "text-gray-500 border-transparent shadow-none";
    const hoverClasses = "hover:text-gray-700";
    return <Button className={`${normalClasses} ${hoverClasses} ${className}`} {...rest} />
}
