import {GhostButton, PrimaryButton} from "../ui/Button";
import {Card} from "../ui/Card";
import {Container} from "../ui/Container";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {AuthService} from "../../services/AuthService";
import {useLoading} from "../../hooks/useLoading";
import {GuestGuard} from "./guards/GuestGuard";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {NotificationManager} from "../ui/NotificationManager";
import React from "react";
import {yup} from "../../extension/yup";

const Schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required(),
});

export const Login = () => {

    const {loading, setLoading} = useLoading();

    const login = async (data: {email: string, password: string}) => {
        setLoading(true);
        try {
            await new AuthService().login(data.email, data.password);
        } catch (e) {
            NotificationInvoker("error", "Login fehlgeschlagen", "Bitte überprüfen Sie Ihre Anmeldedaten");
        }
        setLoading(false);
    }

    return <GuestGuard>
        <div className={"w-screen h-screen bg-gray-100 flex"}>
            <Container size={"md"} className={"my-auto w-full"}>
                <p className={"text-xl font-semibold text-center text-gray-500 mb-6"}>taximeter.ch</p>
                <Card>
                    <Form initialValues={{email: "", password: ""}}
                          validationSchema={Schema}
                          onSubmit={(data) => {
                              login(data);
                          }}
                    >
                        <div className="space-y-6">
                            <FormGroup name={"email"} title={"E-Mail"} description={"Bitte geben Sie Ihre E-Mail ein"}>
                                <FormInput type="text" name={"email"}/>
                            </FormGroup>
                            <FormGroup title={"Password"} description={"Bitte geben Sie Ihr Passwort ein"}>
                                <FormInput type="password" name={"password"}/>
                            </FormGroup>
                            <div className="space-x-3">
                                <PrimaryButton as={"button"} type={"submit"} loading={loading}>Anmelden</PrimaryButton>
                                <GhostButton as={"a"} href={"/password-reset"}>Passwort vergessen</GhostButton>
                            </div>
                        </div>
                    </Form>
                </Card>
            </Container>
        </div>
        <NotificationManager/>
    </GuestGuard>
};