import React, {useState} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {PrimaryButton} from "../ui/Button";
import {TableActions} from "../ui/TableActions";
import {usePagingController} from "../../hooks/usePagingController";
import Paginator from "../ui/Paginator";
import {ExpensesService} from "../../services/ExpensesService";
import {Money} from "../ui/concrete/Money";
import {useAuth} from "../../hooks/useAuth";
import {CreateExpensesModal} from "../modals/CreateExpensesModal";
import {Plate} from "../ui/Plate";


interface Props {}

export const ExpensesPage: React.FC<Props> = () => {

    const [modalOpen, setModalOpen] = useState(false);
    const {controller} = usePagingController({
        perPage: 25,
        serviceMethod: (options) => {
            return new ExpensesService().getAll(options)
        }
    });

    const {user} = useAuth();

    return <AppLayout title={"Kosten"}>
        <div className="space-y-6">
            <TableActions>
                <div className="w-full">
                    <div className="flex gap-2">
                        <PrimaryButton onClick={() => setModalOpen(true)} className={"ml-auto"}>Hinzufügen</PrimaryButton>
                    </div>
                </div>
            </TableActions>

            <DataTable>
                <DataTableHeader>
                    <DataTableCell>
                        Kategorie
                    </DataTableCell>
                    <DataTableCell>
                        Betrag
                    </DataTableCell>
                    <DataTableCell>
                        Fahrer
                    </DataTableCell>
                    <DataTableCell>
                        Fahrzeug
                    </DataTableCell>
                </DataTableHeader>
                {controller.data.map(entry => {
                    return <DataTableRow key={entry.id}>
                        <DataTableCell>
                            {entry.category}
                        </DataTableCell>
                        <DataTableCell>
                            <Money value={entry.amount} currency={user!.currency} />
                        </DataTableCell>
                        <DataTableCell>
                            {entry.driver?.firstname} {entry.driver?.lastname}
                        </DataTableCell>
                        <DataTableCell>
                            {entry.vehicle.name}
                            <span className={"ml-2"}>
                                <Plate plate={entry.vehicle.plate!} />
                            </span>
                        </DataTableCell>
                    </DataTableRow>
                })}
            </DataTable>
            {modalOpen && <CreateExpensesModal onClose={() => {
                controller.fetch();
                setModalOpen(false);
            }}/>}
            <div className="flex">
                <div className="ml-auto">
                    <Paginator controller={controller} />
                </div>
            </div>
        </div>
    </AppLayout>
};