import React, {useEffect, useRef} from "react";
import {AppLayout} from "../layout/AppLayout";
import {useParams} from "react-router-dom";
import {Form} from "../ui/Form";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Card} from "../ui/Card";
import {PrimaryButton} from "../ui/Button";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import {useRouter} from "../../hooks/useRouter";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {yup} from "../../extension/yup";
import {useAuth} from "../../hooks/useAuth";
import {CompanySelect} from "../ui/async-selects/CompanySelect";
import {Vehicle} from "../../models/Vehicle";
import {VehicleService} from "../../services/VehicleService";
import {TaximeterSelect} from "../ui/async-selects/TaximeterSelect";
import {useField} from "formik";


interface Props {

}

const Schema = yup.object().shape({
    name: yup.string().required(),
    description: yup.string(),
    company_id: yup.string().required(),
    taximeter_id: yup.string().required(),
    license: yup.string(),
    plate: yup.string(),
    vin: yup.string(),
    model: yup.string(),
    make: yup.string(),
    year: yup.string(),
    passengers: yup.string(),
    trunk: yup.string(),
});

const TaximeterField = () => {
    const [companyField] = useField("company_id");

    return <TaximeterSelect name={"taximeter_id"}
                            filter={option => {
                                if (!companyField.value) {
                                    return true;
                                }
                                return option.data.data.company_id === companyField.value;
                            }}
    />
};

export const VehiclesDetail: React.FC<Props> = () => {

    const params = useParams<"id">();
    const router = useRouter();
    const payload = useRef<Partial<Vehicle>>({

    });

    const {permissions} = useAuth();

    const forceUpdate = useForceUpdate();

    const isCreating = params.id === "new";

    const hydrate = async (id: string | undefined) => {
        if (isCreating || !id) {
            return;
        }

        try {
            const vehicle = await new VehicleService().get(id);

            payload.current = {
                name: vehicle.name,
                description: vehicle.description,
                company_id: vehicle.company_id,
                taximeter_id: vehicle.taximeter_id,
                license: vehicle.license,
                plate: vehicle.plate,
                vin: vehicle.vin,
                model: vehicle.model,
                make: vehicle.make,
                year: vehicle.year,
                passengers: vehicle.passengers,
                trunk: vehicle.trunk,
            }
            forceUpdate();
        } catch (e) {
            router.to("/vehicles");
        }
    }

    useEffect(() => {
        hydrate(params.id);
    }, [params.id]);

    return <AppLayout title={isCreating
            ? "Vehicles / New"
            : `Vehicles / ${params.id}`
        }>
            <Card>
                <Form initialValues={payload.current}
                      enableReinitialize={true}
                      onSubmit={async (data, helpers) => {
                          try {
                              if (!isCreating) {
                                  await new VehicleService().update(params.id!, data);
                                  NotificationInvoker("success", "Erfolgreich", "Fahrzeug erfolgreich gespeichert");
                                  router.to("/vehicles");
                              } else {
                                  await new VehicleService().create(data);
                                  NotificationInvoker("success", "Erfolgreich", "Fahrzeug erfolgreich gespeichert");
                                  router.to("/vehicles");
                              }
                          } catch (e: any) {
                              e.validation && helpers.setErrors(e.validation);
                              NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                          }
                      }}
                      validationSchema={Schema}
                >
                    <div className="space-y-6">
                        <FormGroup title={"Name"} name={"name"}>
                            <FormInput type={"text"} name={"name"} />
                        </FormGroup>

                        <FormGroup title={"Beschreibung"} name={"description"}>
                            <FormInput as={"textarea"} name={"description"} />
                        </FormGroup>

                        <div className="grid md:grid-cols-2 gap-4">
                            {permissions?.vehicles.setCompany && <div className="col-span-1">
                                <FormGroup title={"Unternehmen"} name={"company_id"}>
                                    <CompanySelect name={"company_id"}
                                                   onChange={() => forceUpdate()}
                                    />
                                </FormGroup>
                            </div>}
                            {permissions?.vehicles.setTaximeter && <div className="col-span-1">
                                <FormGroup title={"Taximeter"} name={"taximeter_id"}>
                                    <TaximeterField />
                                </FormGroup>
                            </div>}
                        </div>

                        <div className="grid md:grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <FormGroup title={"Lizenz"} name={"license"}>
                                    <FormInput type={"text"} name={"license"} />
                                </FormGroup>
                            </div>
                            <div className="col-span-1">
                                <FormGroup title={"Kennzeichen"} name={"plate"}>
                                    <FormInput type={"text"} name={"plate"} />
                                </FormGroup>
                            </div>
                            <div className="col-span-1">
                                <FormGroup title={"Vin"} name={"vin"}>
                                    <FormInput type={"text"} name={"vin"} />
                                </FormGroup>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-3 gap-4">
                            <div className="col-span-1">
                                <FormGroup title={"Modell"} name={"model"}>
                                    <FormInput type={"text"} name={"model"} />
                                </FormGroup>
                            </div>
                            <div className="col-span-1">
                                <FormGroup title={"Hersteller"} name={"make"}>
                                    <FormInput type={"text"} name={"make"} />
                                </FormGroup>
                            </div>
                            <div className="col-span-1">
                                <FormGroup title={"Baujahr"} name={"year"}>
                                    <FormInput type={"text"} name={"year"} />
                                </FormGroup>
                            </div>
                        </div>

                        <div className="grid md:grid-cols-2 gap-4">
                            <div className="col-span-1">
                                <FormGroup title={"Beifahrer"} name={"passengers"}>
                                    <FormInput type={"text"} name={"passengers"} />
                                </FormGroup>
                            </div>
                            <div className="col-span-1">
                                <FormGroup title={"Kofferraum"} name={"trunk"}>
                                    <FormInput type={"text"} name={"trunk"} />
                                </FormGroup>
                            </div>
                        </div>

                        <PrimaryButton size={"lg"} type={"submit"}>
                            {isCreating && "Erstellen"}
                            {!isCreating && "Speichern"}
                        </PrimaryButton>
                    </div>
                </Form>
            </Card>
        </AppLayout>
};