import React, {useRef} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../ui/Modal";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {DriverSelect} from "../ui/async-selects/UserSelect";
import {ExpensesService} from "../../services/ExpensesService";
import {FormToggle} from "../ui/Checkbox";
import {Expenses, ExpensesCategory} from "../../models/Expenses";
import {VehicleSelect} from "../ui/async-selects/VehicleSelect";
import {useAuth} from "../../hooks/useAuth";
import {FormDate} from "../ui/FormDate";
import {yup} from "../../extension/yup";


interface Props {
    onClose: () => void;
}

const Schema = yup.object().shape({
    category: yup.string().required(),
    date: yup.date().required(),
    amount: yup.number().required(),
    driver_id: yup.string().required(),
    vehicle_id: yup.string().required(),
    description: yup.string().required(),
})

export const CreateExpensesModal: React.FC<Props> = (props) => {

    const payload = useRef<Partial<Expenses>>({
        category: ExpensesCategory.Fuel,
    });

    const {user} = useAuth();

    return <Modal>
        <ModalHeader>
            Kosten hinzufügen
        </ModalHeader>
        <Form initialValues={payload.current}
              enableReinitialize={true}
              validationSchema={Schema}
              onSubmit={async (values) => {
                  try {
                      await new ExpensesService().create(values);
                      NotificationInvoker("success", "Erfolgreich", "Kosten erfolgreich hinzugefügt");

                      props.onClose();
                  } catch (e) {
                      NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                  }
              }}
        >
            <ModalBody>
                <div className="space-y-6">

                    <FormGroup name={"category"} title={"Kategorie"}>
                        <div className="flex row space-x-6">
                            {Object.values(ExpensesCategory).map((category) => {
                                return <FormToggle
                                    type={"radio"}
                                    name={"category"}
                                    value={category}
                                >
                                    {category}
                                </FormToggle>
                            })}
                        </div>
                    </FormGroup>

                    <FormGroup title={"Datum"} name={"date"}>
                        <FormDate name={"date"} />
                    </FormGroup>

                    <FormGroup title={"Betrag"} name={"amount"}>
                        <div className="flex items-center gap-2">
                            <FormInput type={"number"} min={0} name={"amount"} />
                            <div>
                                {user!.currency.toUpperCase()}
                            </div>
                        </div>
                    </FormGroup>

                    <FormGroup title={"Fahrer"} name={"driver_id"}>
                        <DriverSelect name={"driver_id"} />
                    </FormGroup>

                    <FormGroup title={"Fahrzeug"} name={"vehicle_id"}>
                        <VehicleSelect name={"vehicle_id"} />
                    </FormGroup>

                    <FormGroup title={"Beschreibung"} name={"description"}>
                        <FormInput as={"textarea"} name={"description"} style={{height: 100}} />
                    </FormGroup>
                </div>

            </ModalBody>
            <ModalFooter>
                <GhostButton onClick={() => props.onClose()} type={"button"}>Abbrechen</GhostButton>
                <PrimaryButton onClick={() => {}} type={"submit"}>Speichern</PrimaryButton>
            </ModalFooter>
        </Form>
    </Modal>

}