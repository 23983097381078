import {Navigate} from "react-router-dom";
import React, {useEffect} from "react";
import {useAuth} from "../../hooks/useAuth";

export const Logout = () => {
    const authState = useAuth();

    useEffect(() => {
        authState.logout();
    }, []);

    return <Navigate to="/login" replace />
};