import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {PaginatedResponse} from "../types/PaginatedResponse";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {ServiceMethodOptions} from "../helper/PagingController";
import {User} from "../models/User";
import {TypeId} from "../types/TypeId";


interface GetAllQueryOptions  {
    search?: string;
}

export class UserService extends BaseService {

    public async getAll(options: ServiceMethodOptions, queryOptions: GetAllQueryOptions) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
            ...queryOptions,
        })

        return this.response<PaginatedResponse<User>>(
            client.get(this.BASE_URL + "/users?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

    public async delete(id: TypeId) {
        const client = BearerApiClient();

        return this.response<User>(
            client.delete(this.BASE_URL + `/users/${id}`),
        )
    }

    public async get(id: TypeId) {
        const client = BearerApiClient();

        return this.response<User>(
            client.get(this.BASE_URL + `/users/${id}`)
        )
    }

    public async create(payload: Partial<User>) {
        const client = BearerApiClient();

        return this.response<User>(
            client.post(this.BASE_URL + `/users`, payload)
        )
    }

    public async update(id: TypeId, payload: Partial<User>) {
        const client = BearerApiClient();

        return this.response<User>(
            client.put(this.BASE_URL + `/users/${id}`, payload)
        )
    }

}