import React, {useRef} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../ui/Modal";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import {CompanySelect} from "../ui/async-selects/CompanySelect";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {IKey} from "../../models/Key";
import {KeyService} from "../../services/KeyService";
import {DriverSelect} from "../ui/async-selects/UserSelect";
import {TypeId} from "../../types/TypeId";
import {useAuth} from "../../hooks/useAuth";


interface Props {
    onClose: () => void;
}

export const KeyModal: React.FC<Props> = (props) => {

    const {user, permissions} = useAuth();
    const payload = useRef<Partial<IKey>>({
        name: "",
        company_id: user!.company_id,
        driver_id: "",
    });

    const forceUpdate = useForceUpdate();

    return <Modal>
        <ModalHeader>
            Create New Key
        </ModalHeader>
        <Form initialValues={payload.current}
              enableReinitialize={true}
              onSubmit={async (values, helpers) => {
                  try {
                      await new KeyService().create(values);
                      NotificationInvoker("success", "Key created successfully");

                      props.onClose();
                  } catch (e: any) {
                      e.validation && helpers.setErrors(e.validation);
                      NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                  }
              }}
        >
            <ModalBody>
                <div className="space-y-6">
                    <FormGroup title={"Driver Key"} name={"name"}>
                        <FormInput type={"text"} name={"name"} />
                    </FormGroup>
                    {permissions?.keys.setCompany && <FormGroup title={"Company"} name={"company_id"}>
                        <CompanySelect name={"company_id"} onChange={(value) => {
                            payload.current.company_id = value as TypeId;
                            forceUpdate();
                        }}/>
                    </FormGroup>
                    }
                    {payload.current.company_id && <FormGroup title={"Driver"} name={"driver_id"}>
                        <DriverSelect name={"driver_id"} filter={option => option.data.data.company_id === payload.current.company_id} />
                    </FormGroup>}
                </div>

            </ModalBody>
            <ModalFooter>
                <GhostButton onClick={() => props.onClose()} type={"button"}>Cancel</GhostButton>
                <PrimaryButton onClick={() => {}} type={"submit"}>Save</PrimaryButton>
            </ModalFooter>
        </Form>
    </Modal>

}