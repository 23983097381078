import React from "react";


interface Props {

}

export const TableActions: React.FC<Props> = ({children}) => {
    return <div className={"flex mb-6"}>
        {children}
    </div>
}