import {BaseService} from "./BaseService";
import {TypeId} from "../types/TypeId";
import {BearerApiClient} from "./APIClient";
import {IKey} from "../models/Key";


export class KeyService extends BaseService {

    async delete(id: TypeId) {
        const client = BearerApiClient();

        return this.response<IKey>(
            client.delete(this.BASE_URL + `/keys/${id}`),
        )
    }

    async create(payload: Partial<IKey>) {
        const client = BearerApiClient();

        return this.response<IKey>(
            client.post(this.BASE_URL + '/keys', payload),
        )
    }

}