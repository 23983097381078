import React from "react";
import {useField} from "formik";
import classNames from "classnames";
import {PolymorphicComponent} from "../../types/PolymorphicComponent";

interface BaseProps {
    name: string;
    type: "checkbox" | "radio";
    value: any;
}

type Props<T extends React.ElementType = "input"> = PolymorphicComponent<BaseProps, T>

export function FormToggle<T extends React.ElementType = "input">(props: Props<T>) {

    const {
        type = "checkbox"
    } = props;
    const [field, meta] = useField(props.name);

    const isChecked = () => {
        if (props.type === "radio") {
            return field.value === props.value
        }

        return !!field.value;
    }

    return <div className={""}>
        <label className={"block w-full cursor-pointer flex items-center space-x-1.5"}>
            <span className="inline-flex">
                <input className={classNames({
                    "border border-gray-200 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50": true,
                    "!border-red-400 focus:!ring-red-200 focus:!ring-opacity-50 !bg-red-50": meta.error
                })} checked={isChecked()} type={type} {...field} value={props.value} />
            </span>
            <span className="inline-flex text-sm font-semibold">
                {props.children ?? props.name}
            </span>
        </label>
    </div>
}