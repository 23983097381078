export interface Subscription {
    id: string;
    callback: Callback;
    unsubscribe: Callback;
    sync: boolean;
}

type Callback = () => void;

export default class Subscribable {
    private subscribers: Callback[] = [];

    subscribe(exec: Callback) {
        this.subscribers.push(exec)
    }

    notify() {
        this.subscribers.forEach((exec) => {
            exec();
        })
    }
}