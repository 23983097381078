import {FormInput} from "../FormInput";
import React from "react";
import {SecondaryButton} from "../Button";
import {SearchIcon} from "@heroicons/react/outline";
import {Form} from "../Form";
import {useFormikContext} from "formik";

interface Props {
    loading: boolean;
    onChange: (search: string) => any;
}

export const SearchForm: React.FC<Props> = (props) => {
    return <>
        <Form initialValues={{search: ""}} onSubmit={(data) => {
            props.onChange(data.search);
        }}>
            <SearchFormInner {...props} />
        </Form>
    </>
}

const SearchFormInner: React.FC<Props> = (props) => {
    const { submitForm } = useFormikContext();

    return <div className={"w-full flex gap-2"}>
        <FormInput className={"w-full"} name={"search"} type={"text"} placeholder={"Suchen"} onInput={() => submitForm()} />
        <SecondaryButton type={"submit"} loading={props.loading}>
            {!props.loading && <SearchIcon className={"w-4 h-4"} />}
        </SecondaryButton>
    </div>
}