import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {Taximeter} from "../models/Taximeter";
import {User} from "../models/User";

export interface StatsFleetOverview {
    status: Taximeter["status"];
    total: number;
}

export interface StatsIncomePerDate {
    income: number;
    date: string;
}

export interface StatsIncomePerDriver {
    income: number;
    driver: User;
}

export class StatsService extends BaseService {

    public async fleetOverview() {
        const client = BearerApiClient();

        return this.response<StatsFleetOverview[]>(
            client.get(this.BASE_URL + `/stats/fleet`)
        )
    }

    public async incomePerDay() {
        const client = BearerApiClient();

        return this.response<StatsIncomePerDate[]>(
            client.get(this.BASE_URL + `/stats/income-per-date`)
        )
    }

    public async incomePerDriver() {
        const client = BearerApiClient();

        return this.response<StatsIncomePerDriver[]>(
            client.get(this.BASE_URL + `/stats/income-per-driver`)
        )
    }

}