import React from "react";
import {Container, ContainerProps} from "./Container";


interface ModalProps {
    size?: ContainerProps["size"];
}

export const Modal: React.FC<ModalProps> = ({children, size}) => {
    return <div className="fixed inset-0 flex overflow-y-auto px-4 py-6 sm:px-0 z-50 bg-black/50">
        <Container className={"my-auto w-full"} size={size}>
            <div className="mb-6 rounded-lg shadow-xl transform transition-all w-full mx-auto animate-fade">
                <div className="bg-white rounded-lg">
                    {children}
                </div>
            </div>
        </Container>
    </div>
}

export const ModalFooter: React.FC<{}> = ({children}) => {
    return <div className={"border-t border-t-gray-200 mt-6 p-3 px-4 bg-gray-50 rounded-b-lg shadow-inner"}>
        <div className="flex">
            <div className="flex ml-auto gap-2">
                {children}
            </div>
        </div>
    </div>
}

export const ModalHeader: React.FC<{}> = ({children}) => {
    return <div className={"text-gray-800 text-2xl p-5"}>
        {children}
    </div>
}

export const ModalBody: React.FC<{}> = ({children}) => {
    return <div className={"text-gray-400 pt-3 px-5"}>
        {children}
    </div>
}