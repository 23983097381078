import React, {useEffect, useState} from "react";
import {useField, useFormikContext} from "formik";
import {AsyncPaginate} from 'react-select-async-paginate';
import classNames from "classnames";
import {SingleValue} from "react-select";
import {FormSelectBaseProps, SelectOption} from "./FormSelect";

export type BaseAsyncSelectProps = Omit<FormSelectBaseProps, "options">;
export type SingleOptionWithId = SelectOption & {data: {id: any}};

interface Props extends BaseAsyncSelectProps {
    getOptions: (text: string, options: SingleOptionWithId[]) => Promise<{
        options: SingleOptionWithId[],
        hasMore: boolean
    }>;
    getInitial: (value: string | number | undefined) => Promise<SingleOptionWithId | null>;
    autoSelectInitial?: boolean;
}

const CustomOption = (props: any) => {
    return <div {...props.innerProps} ref={props.innerRef} className={props.cx({
        "option": true,
        "option--is-disabled": props.isDisabled,
        "option--is-focused": props.isFocused,
        "option--is-multi": props.isMulti,
        "option--is-selected": props.isSelected,
    })}>
        <div className={""}>{props.data.label}</div>
        <div className={"text-sm font-normal"}>ID: {props.data.data.id}</div>
    </div>
}

export const AsyncSelect: React.FC<Props> = (props) => {

    const [field, meta] = useField(props.name);
    const {setFieldValue} = useFormikContext();
    const [options, setOptions] = useState<SelectOption[]>([]);

    const handleChange = (option: SingleValue<SelectOption>) => {
        setFieldValue(props.name, option?.value, true);
    };

    const ensureInitial = async () => {
        let value = field.value;
        try {
            const chosen = await props.getInitial(value);
            if (!chosen) {
                return;
            }

            setOptions(options => [...options, chosen]);

            if (props.autoSelectInitial) {
                handleChange(chosen);
            }
        } catch (e) {}
    };

    useEffect(() => {
        ensureInitial();
    }, [field.value]);

    const value = options.find(option => option.value === field.value);

    const filter = (option: SelectOption) => {
        return props.filter?.(option) ?? true;
    }

    const getOptions = async (filter: string, existingOptions: any) => {
        const res = await props.getOptions(filter, existingOptions);

        setOptions(options => [...options, ...res.options]);
        return res;
    };

    return <AsyncPaginate
        onChange={change => {
            handleChange(change);
            props.onChange?.(change?.value);
        }}
        loadOptions={(text, options) => getOptions(text, options)}
        className={classNames({
            "form-select-container": true,
            "has-error": meta.error,
        })}
        components={{
            Option: CustomOption,
        }}
        classNamePrefix={"form-select"}
        value={value}
        placeholder={"Select"}
        isClearable={true}
        filterOption={item => filter(item)}
    />
}