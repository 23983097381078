import React from "react";
import {AsyncSelect, BaseAsyncSelectProps} from "../AsyncSelect";
import {useAsyncSelect} from "../../../hooks/useAsyncSelect";
import {User, UserType} from "../../../models/User";
import {UserService} from "../../../services/UserService";
import {DriverService} from "../../../services/DriverService";
import {Driver} from "../../../models/Driver";



export const UserSelect: React.FC<BaseAsyncSelectProps> = (props) => {

    const {getOptions, getInitial} = useAsyncSelect<User>({
        getAll: (options, query) => new UserService().getAll(options, query),
        getById: (id) => new UserService().get(id),
        getLabel: (user) => `${user.firstname} ${user.lastname}`,
    })

    return (
        <AsyncSelect getOptions={getOptions}
                     getInitial={getInitial}
                     {...props}
        />
    )
}

export const DriverSelect = (props: BaseAsyncSelectProps) => {
    const {getOptions, getInitial} = useAsyncSelect<Driver>({
        getAll: (options, query) => new DriverService().getAll(options, query),
        getById: (id) => new DriverService().get(id),
        getLabel: (driver) => `${driver.firstname} ${driver.lastname}`,
    })

    return (
        <AsyncSelect getOptions={getOptions}
                     getInitial={getInitial}
                     {...props}
        />
    )
}