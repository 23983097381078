import {AppLayout} from "../layout/AppLayout";
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    LabelList,
    Legend,
    Line,
    LineChart,
    Pie,
    PieChart,
    ResponsiveContainer,
    Tooltip, XAxis, YAxis
} from "recharts";
import {Card} from "../ui/Card";
import {Money, moneyFormat} from "../ui/concrete/Money";
import {useAuth} from "../../hooks/useAuth";
import {useEffect, useState} from "react";
import {StatsFleetOverview, StatsIncomePerDate, StatsIncomePerDriver, StatsService} from "../../services/StatsService";
import {Heading} from "../ui/Heading";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {EventLog, EventLogWithTaximeter} from "../../models/EventLog";
import {EventLogService} from "../../services/EventLogService";
import {FormattedDate} from "../ui/concrete/FormattedDate";
import {Badge} from "../ui/Badge";


const CHART_COLORS = {
    fills: [
        "#80b4ff",
        "#b0a9ff",
        "#e09af4",
        "#ff8ad7",
        "#ff7eae",
        "#ff807e",
        "#ff904b",
        "#ffa600",
    ],
    controls: "rgba(0, 0, 0, .1)",
    labels: "rgba(0, 0, 0, .35)",
}

const getChartColor = (i: number) => {
    return CHART_COLORS.fills[
        i % CHART_COLORS.fills.length
    ];
}

export const Dashboard = () => {

    const {user} = useAuth();

    const [fleetStats, setFleetStats] = useState<StatsFleetOverview[]>([]);
    const [incomePerDayStats, setIncomePerDateStats] = useState<StatsIncomePerDate[]>([]);
    const [incomePerDriver, setIncomePerDriver] = useState<StatsIncomePerDriver[]>([]);
    const [eventLogs, setEventLogs] = useState<EventLogWithTaximeter[]>([]);

    const fetchFleetStats = async () => {
        const stats = await new StatsService().fleetOverview();
        setFleetStats(stats);
    }

    const fetchIncomePerDate = async () => {
        const stats = await new StatsService().incomePerDay();
        setIncomePerDateStats(stats);
    }

    const fetchIncomePerDriver = async () => {
        const stats = await new StatsService().incomePerDriver();
        setIncomePerDriver(stats)
    }

    const fetchEventLogs = async () => {
        const logs = await new EventLogService().getAll({page: 1, perPage: 6});
        setEventLogs(logs.data);
    }

    useEffect(() => {
        fetchFleetStats();
        fetchIncomePerDate();
        fetchIncomePerDriver();
        fetchEventLogs();
    }, []);



    return <AppLayout title={"Dashboard"}>
            <div className="grid md:grid-cols-3 gap-4">
                <div className={""}>
                    <Card>
                        <Heading>Flotten Übersicht</Heading>
                        <ResponsiveContainer height={250}>
                            <PieChart>
                                <Pie data={fleetStats}
                                     startAngle={90}
                                     endAngle={-270}
                                     dataKey="total"
                                     nameKey="status"
                                     outerRadius={"100%"}
                                     innerRadius={"65%"}
                                     isAnimationActive={false}
                                >
                                    <LabelList dataKey="total" position="inside" stroke={"none"} fill={"rgba(255, 255, 255, .75)"} />
                                    <Label value={`Total: ${fleetStats.reduce((acc, current) => acc + current.total, 0)}`}
                                           position="center"
                                           fill={CHART_COLORS.labels}
                                    />
                                    {fleetStats.map((_, index) => <Cell fill={getChartColor(index)}/>)}
                                </Pie>
                                <Legend iconSize={12} />
                                <Tooltip/>
                            </PieChart>
                        </ResponsiveContainer>
                    </Card>
                </div>
                <div className="">
                    <Card>
                        <Heading>Einkommen pro Tag (30d)</Heading>
                        <ResponsiveContainer height={250}>
                            <LineChart data={incomePerDayStats}>
                                <CartesianGrid strokeDasharray="3 3" stroke={CHART_COLORS.controls} />
                                <XAxis dataKey="date"
                                       xAxisId={0}
                                       label={{ value: "Datum", position: "insideRight", dy: -30, opacity: 0.3 }}
                                       stroke={CHART_COLORS.labels}
                                       fontSize={12}
                                />
                                <YAxis label={{ value: `Income (${user!.currency.toUpperCase()})`, position: "insideLeft", angle: -90, opacity: 0.3 }}
                                       stroke={CHART_COLORS.labels}
                                       fontSize={10}
                                       width={25}
                                       tickFormatter={value => moneyFormat(user!.currency, value)}
                                       tick={{dx: 15, dy: 5, textAnchor: "start", fontSizeAdjust: 10}}
                                       tickCount={1}
                                />

                                <Line
                                    type={"monotone"}
                                    dataKey={"income"}
                                    stroke={getChartColor(0)}
                                    fill={"#fff"}
                                    isAnimationActive={false}
                                />
                                <Line
                                    type={"monotone"}
                                    dataKey={"costs"}
                                    stroke={getChartColor(4)}
                                    fill={"#fff"}
                                    isAnimationActive={false}
                                />
                                <Tooltip
                                    formatter={(value: any) => <Money currency={user!.currency} value={value} />}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </Card>
                </div>
                <div className="">
                    <Card>
                        <Heading>Einkommen pro Fahrer (30d)</Heading>
                        <ResponsiveContainer height={250}>
                            <BarChart data={incomePerDriver}>
                                <CartesianGrid strokeDasharray="3 3" stroke={CHART_COLORS.controls} />
                                <XAxis dataKey={data => {
                                    if (!data.driver) {
                                        return null;
                                    }

                                    return `${data.driver.firstname} ${data.driver.lastname}`
                                }}
                                       xAxisId={0}
                                       stroke={CHART_COLORS.labels}
                                       angle={0}
                                       fontSize={12}
                                />
                                <YAxis dataKey={"income"}
                                       yAxisId={1}
                                       label={{ value: `Income (${user!.currency.toUpperCase()})`, position: "insideLeft", angle: -90, opacity: 0.3 }}
                                       stroke={CHART_COLORS.labels}
                                       fontSize={10}
                                       width={25}
                                       tickFormatter={value => moneyFormat(user!.currency, value)}
                                       tick={{dx: 15, dy: 5, textAnchor: "start", fontSizeAdjust: 10}}
                                       tickCount={1}
                                />
                                <Bar
                                    dataKey={"income"}
                                    stroke={getChartColor(0)}
                                    fill={getChartColor(0)}
                                    yAxisId={1}
                                    isAnimationActive={false}
                                    barSize={12}

                                />
                                <Tooltip
                                    cursor={false}
                                    formatter={(value: any) => <Money currency={user!.currency} value={value} />}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    </Card>
                </div>
            </div>
            <div className={"flex gap-4 mt-4"}>
                <div className="w-full md:w-3/4">
                    <Card>
                        <Heading>Letzte Events</Heading>
                        <DataTable>
                            <DataTableHeader>
                                <DataTableCell>Event</DataTableCell>
                                <DataTableCell>Zeitpunkt</DataTableCell>
                                <DataTableCell>Taximeter</DataTableCell>
                            </DataTableHeader>
                            {eventLogs.map(log => (
                                <DataTableRow key={log.id}>
                                    <DataTableCell>
                                        <Badge fill={"red"}>{log.event}</Badge>
                                    </DataTableCell>
                                    <DataTableCell>
                                        <div className={"text-sm"}>
                                            Start: <FormattedDate value={log.start} /><br/>
                                            {log.end && <>Ende: <FormattedDate value={log.end} /></>}
                                        </div>
                                    </DataTableCell>
                                    <DataTableCell>
                                        <div className={"text-sm"}>
                                            <Badge fill={"gray"}>{log.taximeter?.serial || log.taximeter_id}</Badge>
                                        </div>
                                    </DataTableCell>
                                </DataTableRow>
                            ))}
                        </DataTable>
                    </Card>
                </div>
            </div>
        </AppLayout>
}