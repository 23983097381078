import {AppLayout} from "../layout/AppLayout";
import {MapContainer, Marker, Popup, TileLayer, useMap} from "react-leaflet";
import React, {useEffect, useRef, useState} from "react";
import {Utils} from "../../helper/Utils";
import {taximeterStatusToColor, TaximeterWithPosition} from "../../models/Taximeter";
import {TaximeterService} from "../../services/TaximeterService";
import {FormattedDate} from "../ui/concrete/FormattedDate";
import {DivIcon, LatLng} from "leaflet";
import {renderToStaticMarkup} from "react-dom/server";
import classNames from "classnames";
import {CalendarIcon, ChipIcon, UserIcon} from "@heroicons/react/outline";
import {Badge} from "../ui/Badge";
import Taxi from "../../images/taxi.svg";


interface LiveMapProps {
    lockedTo?: string;
    taximeters: TaximeterWithPosition[];
    selectedTaximeter: TaximeterWithPosition | null;
}

const LiveMap: React.FC<LiveMapProps> = (props) => {
    const map = useMap();

    useEffect(() => {
        if(props.taximeters[0].latest_position) {
            map.invalidateSize();
            map.setView(new LatLng(props.taximeters[0].latest_position.lat, props.taximeters[0].latest_position.long));
        }
    }, []);

    useEffect(() => {
        if (props.selectedTaximeter?.latest_position) {
            map.setView(new LatLng(props.selectedTaximeter.latest_position.lat, props.selectedTaximeter.latest_position.long), 14);
        }
    }, [props.selectedTaximeter]);

    const TaximeterIcon: React.FC<{taximeter: TaximeterWithPosition}> = ({taximeter}) => {
        return <div className={classNames({
            "marker-pin": true,
            [`status-${taximeter.status}`]: true,
        })}>
            <img src={Taxi} className={classNames({
                [`flipped`]: taximeter.latest_position.angle_to_last > 180,
            })}/>
            <span>
                {taximeter.serial}
            </span>
            <div className="angle-direction-container">
                <i className='angle-direction'
                   style={{
                       transform: `rotate(${taximeter.latest_position.angle_to_last}deg)`,
                   }}>
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-3 w-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 10l7-7m0 0l7 7m-7-7v18" />
                    </svg>
                </i>
            </div>
        </div>
    }

    return <>
        {props.taximeters.map(taximeter => (
            <>
                {taximeter.latest_position && <Marker position={new LatLng(taximeter.latest_position.lat, taximeter.latest_position.long)}
                        key={`taximeter-marker-${taximeter.id}`}
                        icon={new DivIcon({
                            className: 'taxi-map-icon',
                            html: renderToStaticMarkup(<TaximeterIcon taximeter={taximeter}/>),
                            iconSize:     [30, 30], // size of the icon
                            iconAnchor:   [20, 20], // point of the icon which will correspond to marker's location
                            popupAnchor: [0, -20],
                        })}>
                    <Popup>
                        <div>
                            Serien-Nr.: {taximeter.serial} <br/>
                            <FormattedDate value={taximeter.latest_position.position_date} /> <br/>
                            Status: {taximeter.status} <br/>
                            Geschwindigkeit: {taximeter.latest_position.speed} km/h <br/>
                        </div>
                    </Popup>
                </Marker>}
            </>
        ))}
    </>
}

export const Map = () => {

    const [taximeters, setTaximeters] = useState<TaximeterWithPosition[]>([]);
    const [selectedTaximeter, setSelectedTaximeter] = useState<TaximeterWithPosition | null>(null);

    const refreshMap = async () => {
        setTaximeters(await new TaximeterService().withPositions());
    }

    useEffect(() => {
        refreshMap();

        const interval = setInterval(() => {
            refreshMap();
        }, 10000);

        return () => {
            clearInterval(interval);
        }
    }, []);


    return <AppLayout title={"Map"} full>
            <div className={"h-full flex flex-col md:flex-row"}>
                <div className="w-full h-full">
                    <MapContainer zoom={14} minZoom={6} maxZoom={17} style={{height: "100%"}} center={Utils.defaultMapCenter}>
                        <TileLayer
                            attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                            url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                        />
                        {taximeters.length > 0 &&
                            <LiveMap taximeters={taximeters} selectedTaximeter={selectedTaximeter} />
                        }
                    </MapContainer>
                </div>
                <div className="shrink-0 md:h-full max-w-full overflow-auto flex flex-row md:flex-col md:block md:w-56 border-l bg-white border-gray-300 drop-shadow-md relative z-10">
                    {taximeters.map(taximeter => (
                        <div className={"shrink-0 p-3 border w-64 md:w-full md:border-0 md:border-b border-gray-200 text-xs space-y-2 cursor-pointer"}
                             onClick={() => {
                                 setSelectedTaximeter(taximeter);
                             }}
                        >
                            <div className={"flex items-center"}>
                                <CalendarIcon className={"w-4 h-4 inline-block mr-2 shrink-0"} />
                                <div className={" truncate"}><FormattedDate value={taximeter.latest_position?.position_date} /></div>
                            </div>
                            <div className="flex items-center">
                                <ChipIcon className={"w-4 h-4 inline-block mr-2 shrink-0"} />
                                <div className={" truncate"}><span>Serien-Nr.: <span className={"font-mono"}>{taximeter.serial}</span></span></div>
                            </div>
                            {taximeter.latest_position?.driver && <div className="flex items-center">
                                <UserIcon className={"w-4 h-4 inline-block mr-2 shrink-0"} />
                                <div className={" truncate"}><span>{taximeter.latest_position.driver.firstname} {taximeter.latest_position.driver.lastname}</span></div>
                            </div>}
                            <div>
                                <Badge fill={taximeterStatusToColor(taximeter.status)}>{taximeter.status}</Badge>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </AppLayout>
}