import React, {useEffect, useState} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {usePagingController} from "../../hooks/usePagingController";
import Paginator from "../ui/Paginator";
import {useAuth} from "../../hooks/useAuth";
import {GetShiftFilter, ShiftService} from "../../services/ShiftService";
import {Kilometers} from "../ui/concrete/Kilometers";
import {Money} from "../ui/concrete/Money";
import {DateFormat, FormattedDate} from "../ui/concrete/FormattedDate";
import {Trip} from "../../models/Trip";
import {Shift} from "../../models/Shift";
import classNames from "classnames";
import {GhostButton, PrimaryButton, SecondaryButton} from "../ui/Button";
import {TripMapModal} from "../modals/TripMapModal";
import {FormSelect} from "../ui/FormSelect";
import {Form} from "../ui/Form";
import {TaximeterSelect} from "../ui/async-selects/TaximeterSelect";
import {DriverSelect} from "../ui/async-selects/UserSelect";
import {Heading} from "../ui/Heading";
import {PaginatedResponse} from "../../types/PaginatedResponse";
import {FormikConsumer} from "formik";
import {Badge} from "../ui/Badge";
import {DocumentDownloadIcon, DocumentIcon, TrashIcon} from "@heroicons/react/outline";
import {FormDate} from "../ui/FormDate";
import dayjs from "dayjs";
import {saveAs} from "file-saver";
import {Duration} from "../ui/concrete/Duration";


interface Props {

}

enum FilterType {
    None = "NONE",
    Taximeter = "taximeter",
    Driver = "driver",
    Date = "date",
}

export const DataBrowser: React.FC<Props> = () => {

    const {controller} = usePagingController({
        perPage: 10,
        serviceMethod: (options) => {
            setSelectedShift(null);
            setSelectedTrip(null);

            let filter = {
                "filters[]": filters,
            };

            if (filters.length == 0) {
                return Promise.resolve<PaginatedResponse<Shift>>({
                    current_page: 0,
                    last_page: 0,
                    data: [],
                });
            }

            return new ShiftService().getAll(options, filter);
        }
    });

    const [filters, setFilters] = useState<GetShiftFilter[]>([]);

    const downloadPdf = async () => {
        const file = await new ShiftService().downloadShiftsPdf({
            "filters[]": filters,
        });

        saveAs(file, "shifts.pdf");
    }

    const downloadShiftPdf = async () => {
        const file = await new ShiftService().downloadShiftPdf(selectedShift!.id);

        saveAs(file, "shift.pdf");
    }

    useEffect(() => {
        controller.resetAndFetch();
    }, [filters]);

    const [selectedShift, setSelectedShift] = useState<Shift | null>();
    const [trips, setTrips] = useState<Trip[]>([]);

    const [selectedTrip, setSelectedTrip] = useState<Trip | null>(null);

    useEffect(() => {
        setTrips([]);
        if (!selectedShift) {
            return;
        }

        (async () => {
            setTrips(await new ShiftService().getTrips(selectedShift.id));
        })()
    }, [selectedShift]);

    const {user} = useAuth();

    return <AppLayout title={"Data Browser"}>
            <Form initialValues={{filterType: FilterType.None, filterId: null, filterStart: new Date(), filterEnd: new Date()}} onSubmit={(data) => {
                let toAdd: string = String(data.filterId);
                if (data.filterType === FilterType.Date) {
                    toAdd = `${dayjs(data.filterStart).format(DateFormat.ISO_DATE)}/${dayjs(data.filterEnd).format(DateFormat.ISO_DATE)}`;
                }

                setFilters(filter => ([
                    ...filter,
                    `${data.filterType}:${toAdd}`
                ]));
            }}>
                <div className={"flex flex-col md:flex-row items-stretch md:items-center mb-3 space-y-3 md:space-y-0 md:space-x-3"}>
                    <FormikConsumer>
                        {({values}) => (
                            <>
                                <div>Filter:</div>
                                <div className={"md:w-64"}>
                                        <FormSelect name={"filterType"} options={[
                                                {label: "Keine", value: FilterType.None},
                                                {label: "Taximeter", value: FilterType.Taximeter},
                                                {label: "Fahrer", value: FilterType.Driver},
                                                {label: "Zeitraum", value: FilterType.Date},
                                            ]}
                                        />
                                </div>
                                {values.filterType === FilterType.Taximeter && <div className="md:w-64">
                                    <TaximeterSelect name={"filterId"} />
                                </div>}
                                {values.filterType === FilterType.Driver && <div className="md:w-64">
                                    <DriverSelect name={"filterId"} />
                                </div>}
                                {values.filterType === FilterType.Date && <>
                                    <div className="md:w-64">
                                        <FormDate name={"filterStart"} />
                                    </div>
                                    <div>
                                        bis
                                    </div>
                                    <div className={"md:w-64"}>
                                        <FormDate name={"filterEnd"} />
                                    </div>
                                </>}
                                {(values.filterId || (values.filterStart && values.filterEnd)) && <div className="md:w-64">
                                    <PrimaryButton type={"submit"}>hinzufügen</PrimaryButton>
                                </div>}
                            </>
                        )}
                    </FormikConsumer>
                </div>
            </Form>
            {filters.length > 0 && <>
                <div className={"flex flex-col md:flex-row gap-2 mb-3"}>
                    {filters.map(filter => <>
                        <Badge fill={"gray"} className={"flex"}>{filter} <TrashIcon className={"w-4 h-4 ml-auto md:ml-2 cursor-pointer"} onClick={() => setFilters(f => f.filter(i => i != filter))}/></Badge>
                    </>)}
                    <GhostButton onClick={() => setFilters([])} size={"sm"}>Filter löschen</GhostButton>
                </div>
            </>}
            <div className="space-y-6">
                {controller.data.length > 0 && <div className="flex">
                    <PrimaryButton onClick={() => downloadPdf()} size={"sm"} className={"ml-auto"}>
                        PDF herunterladen
                        <DocumentDownloadIcon className={"ml-1 w-4 h-4"} />
                    </PrimaryButton>
                </div>}
                <DataTable>
                    <DataTableHeader>
                        <DataTableCell>
                            Id
                        </DataTableCell>
                        <DataTableCell>
                            Start
                        </DataTableCell>
                        <DataTableCell>
                            Ende
                        </DataTableCell>
                        <DataTableCell>
                            Fahrer
                        </DataTableCell>
                        <DataTableCell>
                            Distanz
                        </DataTableCell>
                        <DataTableCell>
                            Einkommen
                        </DataTableCell>
                    </DataTableHeader>
                    {controller.data.map(entry => {
                        return <DataTableRow key={entry.id} onClick={() => setSelectedShift(entry)} className={classNames({
                            "data-table-selected": selectedShift?.id === entry.id,
                            "cursor-pointer": true,
                        })}>
                            <DataTableCell>
                                {entry.id}
                            </DataTableCell>
                            <DataTableCell>
                                <FormattedDate value={entry.start} />
                            </DataTableCell>
                            <DataTableCell>
                                {entry.end && <FormattedDate value={entry.end} />}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.driver && <>{entry.driver.firstname} {entry.driver.lastname}</>}
                            </DataTableCell>
                            <DataTableCell>
                                <Kilometers value={entry.occupied_m + entry.empty_m + entry.special_trip_m} />
                            </DataTableCell>
                            <DataTableCell>
                                <Money value={entry.income} currency={user!.currency} />
                            </DataTableCell>
                        </DataTableRow>
                    })}
                    <DataTableRow className={"data-table-sum"}>
                        <DataTableCell>

                        </DataTableCell>
                        <DataTableCell>

                        </DataTableCell>
                        <DataTableCell>

                        </DataTableCell>
                        <DataTableCell>
                            <strong>Total</strong>
                        </DataTableCell>
                        <DataTableCell>
                            <Kilometers value={controller.data.reduce((acc, entry) => acc + entry.occupied_m + entry.empty_m + entry.special_trip_m, 0)} />
                        </DataTableCell>
                        <DataTableCell>
                            <Money value={controller.data.reduce((acc, entry) => acc + entry.income, 0)} currency={user!.currency} />
                        </DataTableCell>
                    </DataTableRow>
                </DataTable>
                <div className="flex">
                    <div className="ml-auto">
                        <Paginator controller={controller} />
                    </div>
                </div>
            </div>
            {selectedShift && <>
                <div className="flex items-center mt-6">
                    <Heading>Schicht Details</Heading>
                    <PrimaryButton onClick={() => downloadShiftPdf()} size={"sm"} className={"ml-auto mb-6"}>
                        PDF herunterladen
                        <DocumentDownloadIcon className={"ml-1 w-4 h-4"} />
                    </PrimaryButton>
                </div>
                <DataTable>
                    <DataTableHeader>
                        <DataTableCell>Event</DataTableCell>
                        <DataTableCell>Sequenz</DataTableCell>
                        <DataTableCell>Start</DataTableCell>
                        <DataTableCell>Ende</DataTableCell>
                        <DataTableCell>Wartezeit</DataTableCell>
                        <DataTableCell>Dauer</DataTableCell>
                        <DataTableCell>Leer km</DataTableCell>
                        <DataTableCell>Besetzt km</DataTableCell>
                        <DataTableCell>Gesamt Einkommen</DataTableCell>
                        <DataTableCell></DataTableCell>
                    </DataTableHeader>
                    <DataTableRow className={"data-table-selected"}>
                        <DataTableCell>Schicht Start</DataTableCell>
                        <DataTableCell />
                        <DataTableCell><FormattedDate value={selectedShift.start} /></DataTableCell>
                        <DataTableCell /><DataTableCell /><DataTableCell /><DataTableCell />
                        <DataTableCell /><DataTableCell /><DataTableCell />
                    </DataTableRow>

                    {trips.map(trip => (
                        <DataTableRow key={`trip-${trip.id}`}>
                            <DataTableCell>{trip.type}</DataTableCell>
                            <DataTableCell>{trip.sequence}</DataTableCell>
                            <DataTableCell><FormattedDate value={trip.start} /></DataTableCell>
                            <DataTableCell>{trip.end && <FormattedDate value={trip.end} />}</DataTableCell>
                            <DataTableCell>{Duration(trip.waiting_time)}</DataTableCell>
                            <DataTableCell>{Duration(trip.duration)}</DataTableCell>
                            <DataTableCell><Kilometers value={trip.empty_m} /></DataTableCell>
                            <DataTableCell><Kilometers value={trip.occupied_m} /></DataTableCell>
                            <DataTableCell><Money value={trip.income} currency={user!.currency} /></DataTableCell>
                            <DataTableCell>
                                <SecondaryButton size={"sm"} onClick={() => setSelectedTrip(trip)}>Karte</SecondaryButton>
                            </DataTableCell>
                        </DataTableRow>
                    ))}
                    {selectedShift.end && <>
                        <DataTableRow className={"data-table-selected"}>
                            <DataTableCell>Schicht Ende</DataTableCell>
                            <DataTableCell />
                            <DataTableCell><FormattedDate value={selectedShift.end} /></DataTableCell>
                            <DataTableCell /><DataTableCell /><DataTableCell /><DataTableCell />
                            <DataTableCell /><DataTableCell /><DataTableCell />
                        </DataTableRow>
                    </>}
                    <DataTableRow className={"data-table-sum"}>
                        <DataTableCell></DataTableCell>
                        <DataTableCell></DataTableCell>
                        <DataTableCell></DataTableCell>
                        <DataTableCell><strong>Total</strong></DataTableCell>
                        <DataTableCell>{Duration(trips.reduce((acc, entry) => acc + entry.waiting_time, 0))}</DataTableCell>
                        <DataTableCell>{Duration(trips.reduce((acc, entry) => acc + entry.duration, 0))}</DataTableCell>
                        <DataTableCell><Kilometers value={trips.reduce((acc, entry) => acc + entry.empty_m, 0)} /></DataTableCell>
                        <DataTableCell><Kilometers value={trips.reduce((acc, entry) => acc + entry.occupied_m, 0)} /></DataTableCell>
                        <DataTableCell><Money value={trips.reduce((acc, entry) => acc + entry.income, 0)} currency={user!.currency} /></DataTableCell>
                        <DataTableCell>

                        </DataTableCell>
                    </DataTableRow>
                </DataTable>
                {selectedTrip && <TripMapModal onClose={() => setSelectedTrip(null)} trip={selectedTrip} />}
            </>}
        </AppLayout>
};