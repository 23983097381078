import {Currency} from "./Currency";
import {IKey} from "./Key";
import {WithAbilities} from "../types/WithAbilities";

export enum UserType {
    company = "company",
    admin = "admin",
}

export interface User extends WithAbilities<"view" | "delete" | "update" | "ban"> {
    id: number;
    company_id: string;
    type: UserType;
    firstname: string;
    lastname: string;
    phone: string;
    address: string;
    city: string;
    country: string;
    email: string;
    password: string;
    currency: Currency;
    locked: boolean;
    keys: IKey[];
}