import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {TypeId} from "../types/TypeId";
import {Position} from "../models/Position";

export class PositionService extends BaseService {

    public async getByTrip(id: TypeId): Promise<Position[]> {
        const client = BearerApiClient();

        return this.response<Position[]>(
            client.get(this.BASE_URL  + `/trips/${id}/positions`)
        );
    }

}