import {Nav} from "../ui/Nav";
import {NavLink} from "../ui/NavLink";
import React from "react";
import classNames from "classnames";
import {
    CashIcon,
    ChartBarIcon,
    CollectionIcon, GlobeIcon,
    IdentificationIcon,
    LibraryIcon,
    LogoutIcon, UserGroupIcon,
    UserIcon
} from "@heroicons/react/outline";
import {useAuth} from "../../hooks/useAuth";

interface Props {
    open: boolean;
}

export const Sidebar: React.FC<Props> = ({open}) => {

    const {permissions} = useAuth();

    return <div className={classNames({
        "md:h-full md:block bg-gray-800 text-gray-400 transition-all md:flex flex-col overflow-hidden": true,
        "block md:w-64": open,
        "hidden md:block h-0 w-0 md:w-16": !open,
        "absolute inset-0 top-24 md:static": true,
        "z-[999999]": true
    })}>
        <div className="hidden md:flex relative z-10 p-3 h-24 font-semibold text-center flex flex-col border-b border-b-gray-700 shadow-md">
            <div className={"my-auto"}>
                {open && "taximeter.ch"}
                {!open && "TX"}
            </div>
        </div>
        <Nav className={"h-full flex flex-col w-full"}>
            {permissions?.pages.dashboard && <NavLink to={"/"} exact={true} open={open} icon={ChartBarIcon}>
                Dashboard
            </NavLink>}
            {permissions?.pages.companies && <NavLink to={"/companies"} open={open} icon={LibraryIcon}>
                Unternehmen
            </NavLink>}
            {permissions?.pages.users && <NavLink to={"/users"} open={open} icon={UserIcon}>
                Benutzer
            </NavLink>}
            {permissions?.pages.drivers && <NavLink to={"/drivers"} open={open} icon={UserGroupIcon}>
                Fahrer
            </NavLink>}
            {permissions?.pages.vehicles && <NavLink to={"/vehicles"} open={open} icon={IdentificationIcon}>
                Fahrzeuge
            </NavLink>}
            {permissions?.pages.data_browser && <NavLink to={"/data-browser"} open={open} icon={CollectionIcon}>
                Data Browser
            </NavLink>}
            {permissions?.pages.map && <NavLink to={"/map"} open={open} icon={GlobeIcon}>
                Karte
            </NavLink>}
            {permissions?.pages.expenses && <NavLink to={"/expenses"} open={open} icon={CashIcon}>
                Kosten
            </NavLink>}
            <div className={"mt-auto"}/>
            <NavLink to={"/logout"} open={open} icon={LogoutIcon}>
                Abmelden
            </NavLink>
            <div className="mb-6" />
            <div className={"text-center text-gray-500 text-xs mb-6"}>Version 1.0.4</div>
        </Nav>
    </div>
}