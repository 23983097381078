import React, {useEffect, useRef} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../ui/Modal";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {TypeId} from "../../types/TypeId";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {useForceUpdate} from "../../hooks/useForceUpdate";
import {TaximeterService} from "../../services/TaximeterService";
import {Taximeter} from "../../models/Taximeter";
import {FormSelect} from "../ui/FormSelect";
import {CompanySelect} from "../ui/async-selects/CompanySelect";
import {NotificationInvoker} from "../../hooks/useNotificationState";


interface Props {
    onClose: () => void;
    taximeterId?: TypeId;
}

export const TaximeterModal: React.FC<Props> = (props) => {

    const payload = useRef<Partial<Taximeter>>({

    });

    const isCreating = props.taximeterId === undefined;
    const forceUpdate = useForceUpdate();

    const hydrate = async (id?: TypeId) => {
        if (isCreating || !id) {
            return;
        }

        try {
            const tx = await new TaximeterService().get(id);

            payload.current = {
                serial: tx.serial,
                model: tx.model,
                company_id: tx.company_id,
            }
            forceUpdate();
        } catch (e) {
            props.onClose();
        }
    }

    useEffect(() => {
        hydrate(props.taximeterId);
    }, [props.taximeterId]);

    return <Modal>
        <ModalHeader>
            {isCreating
                ? "Create Taximeter"
                : "Update Taximeter"
            }
        </ModalHeader>
        <Form initialValues={payload.current}
              enableReinitialize={true}
              onSubmit={async (values) => {
                  try {
                      if (!isCreating) {
                          const {serial, ...updatePayload} = values;
                          await new TaximeterService().update(props.taximeterId!, updatePayload);
                          NotificationInvoker("success", "Taximeter erfolgreich gespeichert");
                      } else {
                          await new TaximeterService().create(values);
                          NotificationInvoker("success", "Taximeter erfolgreich gespeichert");
                      }

                      props.onClose();
                  } catch (e) {
                      NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                  }
              }}
        >
            <ModalBody>
                    <div className="space-y-6">
                        <FormGroup title={"Serien-Nr."} name={"serial"}>
                            <FormInput type={"text"} name={"serial"} disabled={!isCreating} />
                        </FormGroup>
                        <FormGroup title={"Model"} name={"model"}>
                            <FormSelect name={"model"}
                                        options={[
                                            {label: "P6S", value: "P6S"},
                                            {label: "PL6", value: "PL6"},
                                            {label: "P6C", value: "P6C"},
                                            {label: "P6F", value: "P6F"},
                                        ]}
                            />
                        </FormGroup>
                        <FormGroup title={"Unternehmen"} name={"company"}>
                            <CompanySelect name={"company_id"} />
                        </FormGroup>
                    </div>

            </ModalBody>
            <ModalFooter>
                <GhostButton onClick={() => props.onClose()}>Abbrechen</GhostButton>
                <PrimaryButton type={"submit"}>Speichern</PrimaryButton>
            </ModalFooter>
        </Form>
    </Modal>

}