import create from "zustand";
import * as uuid from "uuid";

export interface Prompt {
    id: string;
    title: string;
    description: string;
    onConfirm: () => any;
    onCancel: () => any;
}

export const PromptConfirm = async (title: string, description: string) => {
    return new Promise<boolean>((res, rej) => {
        const id = uuid.v4();
        usePromptState.getState().addPrompt({
            id: id,
            title,
            description,
            onConfirm: () => {
                res(true);
                usePromptState.getState().removePrompt(id);
            },
            onCancel: () => {
                res(false);
                usePromptState.getState().removePrompt(id);
            }
        })
    });
}

export const usePromptState = create<{
    prompts: Prompt[];
    addPrompt: (prompt: Prompt) => any;
    removePrompt: (id: string) => any;
}>((set, get) => ({
    prompts: [],
    addPrompt: (prompt) => {
        set({
            prompts: [...get().prompts, prompt],
        })
    },
    removePrompt: (id) => {
        set({
            prompts: get().prompts.filter(p => p.id !== id)
        });
    }
}))