import React from "react";
import {Link, useMatch, useResolvedPath} from "react-router-dom";
import {To} from "history";
import classNames from "classnames";


interface Props {
    to: To,
    exact?: boolean;
    open?: boolean;
    icon?: React.FC<React.ComponentProps<'svg'>>

}

export const NavLink: React.FC<Props> = (props) => {
    const exact = props.exact || false;
    const resolved = useResolvedPath(props.to);
    const match = useMatch({path: resolved.pathname, end: exact});

    const IconComponent = props.icon ? props.icon : null;

    const open = props.open;

    return <Link to={props.to}
                 className={classNames({
                     "block p-3 p-3 border-l-2 border-transparent text-sm font-medium leading-5 focus:outline-none focus:text-gray-300 transition": true,
                     "border-transparent text-gray-500 hover:text-gray-300 focus:border-gray-300 hover:bg-gray-700/25": !match,
                     "!border-white bg-gray-700 !text-white": match,
                     "!text-center": !open,
                     "whitespace-nowrap": true,
                 })}
    >
        {IconComponent && <IconComponent className={classNames({
            "inline-block": true,
            "w-4 h-4 mr-3": open,
            "w-6 h-6": !open,
        })} />}
        {open && props.children}
    </Link>
}