import React from "react";
import {AsyncSelect, BaseAsyncSelectProps} from "../AsyncSelect";
import {CompanyService} from "../../../services/CompanyService";
import {useAsyncSelect} from "../../../hooks/useAsyncSelect";
import {Company} from "../../../models/Company";



export const CompanySelect: React.FC<BaseAsyncSelectProps> = (props) => {

    const {getOptions, getInitial} = useAsyncSelect<Company>({
        getAll: (options, query) => new CompanyService().getAll(options, query),
        getById: (id) => new CompanyService().get(id),
        getLabel: (company: Company) => company.name,
    })

    return (
        <AsyncSelect getOptions={getOptions}
                     getInitial={getInitial}
                     {...props}
        />
    )
}