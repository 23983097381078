import {AxiosResponse} from "axios";

export class BaseService {

    get BASE_URL() {
        if (process.env.NODE_ENV === "production") {
            return "https://data.garage2000.ch/api";
        }

        return "http://localhost:8000/api";
    }

    async response<T = any>(fn: Promise<AxiosResponse<T>>) {
        return (await fn).data;
    }

}