import React from "react";
import {useField} from "formik";


interface Props {
    name?: string;
    title: string;
    description?: string;
}

export const FormGroup: React.FC<Props> = (props) => {

    return <div className={"text-base space-y-0.5"}>
        <div className={"uppercase text-gray-600 text-sm font-semibold"}>{props.title}</div>
        {props.description && <div className="text-gray-500/60 text-sm !mt-0">{props.description}</div>}
        {props.children}
        {props.name && <FormError name={props.name} />}
    </div>
}

export const FormError: React.FC<{name: string}> = ({name}) => {
    const [, meta] = useField(name);

    return <>
        {meta.error && <div className={"text-red-400 text-sm"}>{meta.error}</div>}
    </>
}