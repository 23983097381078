import React from "react";
import {PolymorphicComponent} from "../../types/PolymorphicComponent";
import classNames from "classnames";


interface BaseProps {
    className?: string;
    fill?: "blue" | "green" | "red" | "gray";
}
type Props<T extends React.ElementType = "span"> = PolymorphicComponent<BaseProps, T>

export function Badge<T extends React.ElementType = "span">(props: Props<T>) {

    const {
        className = "",
        as: Component = "span",
        children,
        fill,
        ...rest
    } = props;

    return <Component className={[
        classNames({
            "p-1.5 px-3 rounded-md text-xs font-bold select-none": true,
            "bg-blue-50 text-blue-400 border border-blue-200": fill === "blue",
            "bg-green-100 text-green-500 border border-green-300": fill === "green",
            "bg-red-50 text-red-400 border border-red-200": fill === "red",
            "bg-gray-50 text-gray-400 border border-gray-200": fill === "gray",
        }),
        className
    ].join(" ")} {...rest}>
        {children}
    </Component>
}