import React from "react";
import {AsyncSelect, BaseAsyncSelectProps} from "../AsyncSelect";
import {useAsyncSelect} from "../../../hooks/useAsyncSelect";
import {Vehicle} from "../../../models/Vehicle";
import {VehicleService} from "../../../services/VehicleService";



export const VehicleSelect: React.FC<BaseAsyncSelectProps> = (props) => {

    const {getOptions, getInitial} = useAsyncSelect<Vehicle>({
        getAll: (options, query) => new VehicleService().getAll(options, query),
        getById: (id) => new VehicleService().get(id),
        getLabel: (veh) => `${veh.name} - ${veh.plate}`,
    })

    return (
        <AsyncSelect getOptions={getOptions}
                     getInitial={getInitial}
                     {...props}
        />
    )
}