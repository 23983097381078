import {FormSelect, FormSelectBaseProps} from "../FormSelect";
import React from "react";
import {Currency} from "../../../models/Currency";


interface CurrencySelectProps extends Omit<FormSelectBaseProps, "options"> {

}

export const CurrencySelect: React.FC<CurrencySelectProps> = (props) => {

    return <FormSelect {...props} options={
        Object.values(Currency).map(currency => (
            {label: currency.toUpperCase(), value: currency}
        ))
    }/>
}