import React, {useRef, useState} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {TableActions} from "../ui/TableActions";
import {usePagingController} from "../../hooks/usePagingController";
import {PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Paginator from "../ui/Paginator";
import {SearchForm} from "../ui/concrete/SearchForm";
import {Badge} from "../ui/Badge";
import {useAuth} from "../../hooks/useAuth";
import {PromptConfirm} from "../../hooks/usePromptState";
import {TypeId} from "../../types/TypeId";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {KeyService} from "../../services/KeyService";
import {KeyModal} from "../modals/KeyModal";
import {DriverService} from "../../services/DriverService";


interface Props {

}

export const Drivers: React.FC<Props> = () => {

    const filter = useRef<{ search: string | undefined }>({
        search: undefined,
    });

    const [keyModalOpen, setKeyModalOpen] = useState(false);

    const {controller} = usePagingController({
        perPage: 25,
        serviceMethod: (options) => {
            return new DriverService().getAll(options, {
                search: filter.current.search,
            })
        }
    });

    const deleteKey = async (id: TypeId) => {
        const confirm = await PromptConfirm("Sind Sie sicher?", "Dass Sie diesen Schlüssel löschen möchten?");
        if (!confirm) return;

        await new KeyService().delete(id);
        NotificationInvoker("success", "Schlüssel gelöscht");
        controller.fetch();
    };

    const del = async (id: TypeId) => {
        const confirm = await PromptConfirm("Sind Sie sicher?", "Dass Sie diesen Fahrer löschen möchten? Diese Aktion kann nicht mehr rückgängig gemacht werden.");
        if (!confirm) return;

        await new DriverService().delete(id);
        NotificationInvoker("success", "Fahrer gelöscht");
        controller.fetch()
    }

    const {permissions} = useAuth();

    return <AppLayout title={"Fahrer"}>
        <div className="space-y-6">
            <TableActions>
                <div className="w-full">
                    <div className="flex-col md:flex-row flex gap-2">
                        <div className="w-full md:w-4/12">
                            <SearchForm loading={controller.loading} onChange={(search) => {
                                filter.current.search = search;
                                controller.resetAndFetch();
                            }}
                            />
                        </div>
                        <div className={"ml-auto"}>
                            {permissions?.drivers.create &&
                                <PrimaryButton as={Link} to={"/drivers/new"}>Erstellen</PrimaryButton>
                            }
                            {permissions?.keys.create &&
                                <PrimaryButton onClick={() => setKeyModalOpen(true)}>Schlüssel erstellen</PrimaryButton>
                            }
                        </div>
                    </div>
                </div>
            </TableActions>

            <DataTable>
                <DataTableHeader>
                    <DataTableCell>
                        Name
                    </DataTableCell>
                    <DataTableCell>
                        Schlüssel
                    </DataTableCell>
                    <DataTableCell size={2}>

                    </DataTableCell>
                </DataTableHeader>
                {controller.data.map(entry => {
                    return <DataTableRow key={entry.id}>
                        <DataTableCell>
                            {entry.firstname} {entry.lastname}
                        </DataTableCell>
                        <DataTableCell>
                            {entry.keys?.map(key => (
                                <Badge fill={"blue"} className={"inline-flex items-center"}>
                                    {key.name}
                                    {key.__abilities.delete &&
                                        <span className={"cursor-pointer"} onClick={() => deleteKey(key.id)}>
                                            <TrashIcon className={" w-3 h-3 ml-1"}/>
                                        </span>}
                                </Badge>
                            ))}
                        </DataTableCell>
                        <DataTableCell className={"text-right"}>

                            {entry.__abilities.update && <GhostButton as={Link} to={"/drivers/" + entry.id}>
                                <PencilIcon className={"w-5 h-5"}/>
                            </GhostButton>}

                            {entry.__abilities.delete && <GhostButton onClick={() => del(entry.id)}><TrashIcon
                                className={"w-5 h-5"}/>
                            </GhostButton>}

                        </DataTableCell>
                    </DataTableRow>
                })}
            </DataTable>
            {keyModalOpen && <KeyModal onClose={() => {
                setKeyModalOpen(false);
                controller.fetch();
            }}/>}
            <div className="flex">
                <div className="ml-auto">
                    <Paginator controller={controller}/>
                </div>
            </div>
        </div>
    </AppLayout>
};