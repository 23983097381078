import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {PaginatedResponse} from "../types/PaginatedResponse";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {ServiceMethodOptions} from "../helper/PagingController";
import {TypeId} from "../types/TypeId";
import {Shift} from "../models/Shift";
import {Trip} from "../models/Trip";

export type GetShiftFilter = string;

export interface GetShiftOptions {
    "filters[]"?: GetShiftFilter[];
}

export class ShiftService extends BaseService {

    public async getAll(options: ServiceMethodOptions, queryOptions: GetShiftOptions = {}) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
            ...queryOptions,
        })

        return this.response<PaginatedResponse<Shift>>(
            client.get(this.BASE_URL + "/shifts?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

    public async downloadShiftsPdf(queryOptions: GetShiftOptions = {}) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...queryOptions,
        });

        const response = await client.get(this.BASE_URL + "/shifts/export?" + qs, {
            responseType: "blob"
        });
        return response.data;
    }

    public async downloadShiftPdf(id: TypeId) {
        const client = BearerApiClient();

        const response = await client.get(this.BASE_URL + "/shifts/" + id + "/export", {
            responseType: "blob"
        });
        return response.data;
    }

    public async getTrips(shiftId: TypeId) {
        const client = BearerApiClient();

        return this.response<Trip[]>(
            client.get(this.BASE_URL + `/shifts/${shiftId}/trips`)
        )
    }

}