import {FormSelect, FormSelectBaseProps} from "../FormSelect";
import React from "react";


interface CountrySelectProps extends Omit<FormSelectBaseProps, "options"> {

}

export const CountrySelect: React.FC<CountrySelectProps> = (props) => {

    return <FormSelect {...props} options={[
        {label: "Switzerland", value: "CH"},
        {label: "Germany", value: "DE"},
    ]}/>
}