import * as React from "react";
import PagingController from "../../helper/PagingController";
import {PaginatorButton} from "./Button";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/outline";

export interface PaginatorProps {
    controller: PagingController<any>
}

export default class Paginator extends React.Component<PaginatorProps, {}> {

    static getPaginationOptions = ({listLength = 5, currentPage = 0, totalPages = 0}) => {
        const offset = Math.floor((listLength-2) / 2);

        let start = currentPage - offset;
        let end = currentPage + offset;

        let rtn = [];

        if (totalPages <= listLength) {
            start = 1;
            end = totalPages;
        } else if (currentPage < offset*2) {
            start = 1;
            end = listLength-1;
        } else if ((currentPage + offset) >= totalPages) {
            start = totalPages - listLength + 2;
            end = totalPages;
        }

        rtn.push(1);
        for(let i = start; i <= end; i++) {
            if(i != 1 && i < totalPages) {
                rtn.push(i);
            }
        }
        rtn.push(totalPages);

        return rtn;
    };

    handleKeyDown = (event: DocumentEventMap["keydown"]) => {
        switch( event.keyCode ) {
            case 37:
                this.back();
                break;
            case 39:
                this.forward();
                break;
            default:
                break;
        }
    }

    componentDidMount(): void {
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount(): void {
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    render() {
        return <div className={"flex pagination"}>
            <PaginatorButton size="sm" onClick={() => this.back()}>
                <ChevronLeftIcon className={"w-4 h-4"} />
            </PaginatorButton>

            {this.renderPages()}

            <PaginatorButton size="sm" onClick={() => this.forward()}>
                <ChevronRightIcon className={"w-4 h-4"} />
            </PaginatorButton>
        </div>
    }

    back() {
        if(this.canGoBack()) {
            this.onPageChange(this.page - 1);
        }
    }

    forward() {
        if(this.canGoForward()) {
            this.onPageChange(this.page + 1);
        }
    }


    getPageItems(currentPage: number, nrOfPages: number) {
        var delta = 2,
            range = [],
            rangeWithDots = [],
            l;

        if (nrOfPages <= 1) {
            return [1];
        }

        range = Paginator.getPaginationOptions({listLength: 7, currentPage: currentPage, totalPages: nrOfPages});


        for (let i of range) {
            if (l) {
                if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    renderPages() {
        let items = this.getPageItems(this.page +1, Math.ceil(this.pageCount));

        return items.map((item) => {
            if(item === "...") {
                return <PaginatorButton className={"page-item"}>
                    ...
                </PaginatorButton>
            } else {

                return <PaginatorButton isActive={this.page+1 === item} onClick={() => {
                    this.onPageChange(+item - 1);
                }}>
                    {item}
                </PaginatorButton>
            }
        })
    }

    canGoBack() {
        return this.page > 0
    }

    canGoForward() {
        return this.page < this.pageCount -1;
    }

    get page() {
        return this.props.controller.page;
    }

    get pageCount() {
        return this.props.controller.pageCount;
    }

    get onPageChange() {
        return this.props.controller.onPageChange;
    }

}