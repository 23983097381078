import React from "react";


interface Props {

}

export const DataTable: React.FC<Props> = ({children}) => {
    return <div className={"border rounded-md border-gray-200 shadow-sm overflow-auto"}>
        <table className={"data-table w-full divide-y divide-gray-200 divide-dashed"}>
            {children}
        </table>
    </div>
}