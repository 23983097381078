import React, {useRef, useState} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {TableActions} from "../ui/TableActions";
import {usePagingController} from "../../hooks/usePagingController";
import {PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Paginator from "../ui/Paginator";
import {SearchForm} from "../ui/concrete/SearchForm";
import {useAuth} from "../../hooks/useAuth";
import {PromptConfirm} from "../../hooks/usePromptState";
import {TypeId} from "../../types/TypeId";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {VehicleService} from "../../services/VehicleService";
import {TaximeterModal} from "../modals/TaximeterModal";


interface Props {

}

export const Vehicles: React.FC<Props> = () => {

    const filter = useRef<{ search: string | undefined }>({
        search: undefined,
    })

    const {controller} = usePagingController({
        perPage: 25,
        serviceMethod: (options) => {
            return new VehicleService().getAll(options, {
                search: filter.current.search,
            })
        }
    });

    const [taximeterModalOpen, setTaximeterModalOpen] = useState(false);
    const [selectedTaximeterId, setSelectedTaximeter] = useState<TypeId | undefined>(undefined);

    const del = async (id: TypeId) => {
        const confirm = await PromptConfirm("Sind Sie sicher?", "Dass Sie dieses Fahrzeug löschen möchten? Diese Aktion kann nicht mehr rückgängig gemacht werden.");
        if (!confirm) return;

        await new VehicleService().delete(id);
        NotificationInvoker("success", "Fahrzeug gelöscht");
        controller.fetch()
    }

    const {permissions} = useAuth();

    return <AppLayout title={"Fahrzeuge"}>
            <div className="space-y-6">
                <TableActions>
                    <div className="w-full">
                        <div className="flex-col md:flex-row flex gap-2">
                            <div className="w-full md:w-4/12">
                                <SearchForm loading={controller.loading} onChange={(search) => {
                                    filter.current.search = search;
                                    controller.resetAndFetch();
                                }}
                                />
                            </div>
                            <div className="ml-auto flex items-stretch gap-2">
                                {permissions?.taximeters.create &&
                                    <PrimaryButton onClick={() => setTaximeterModalOpen(true)}>Taximeter hinzufügen</PrimaryButton>
                                }
                                {permissions?.vehicles.create &&
                                    <PrimaryButton as={Link} to={"/vehicles/new"}>Erstellen</PrimaryButton>
                                }
                            </div>
                        </div>
                    </div>
                </TableActions>

                <DataTable>
                    <DataTableHeader>
                        <DataTableCell>
                            Name
                        </DataTableCell>
                        <DataTableCell>
                            Taximeter
                        </DataTableCell>
                        <DataTableCell>
                            Modell
                        </DataTableCell>
                        <DataTableCell>
                            Unternehmen
                        </DataTableCell>
                        <DataTableCell size={2}>

                        </DataTableCell>
                    </DataTableHeader>
                    {controller.data.map(entry => {
                        return <DataTableRow key={entry.id}>
                            <DataTableCell>
                                {entry.name}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.taximeter && (<div>
                                    {entry.taximeter?.__abilities?.update &&
                                        <div className={"text-blue-500 cursor-pointer"} onClick={() => {
                                            setTaximeterModalOpen(true);
                                            setSelectedTaximeter(entry.taximeter?.id);
                                        }}>ID: {entry.taximeter.id}</div>
                                    }
                                    <div className="text-gray-400 text-sm">Serien-Nr.: {entry.taximeter.serial}</div>
                                </div>)}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.model}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.company?.__abilities.update ? (
                                  <Link to={`/companies/${entry.company.id}`} className={"text-blue-500"}>{entry.company.name}</Link>
                                ) : (
                                    <>{entry.company?.name}</>
                                )}
                            </DataTableCell>
                            <DataTableCell className={"text-right"}>

                                {entry.__abilities.update &&  <GhostButton as={Link} to={"/vehicles/" + entry.id}>
                                    <PencilIcon className={"w-5 h-5"}/>
                                </GhostButton>}

                                {entry.__abilities.delete && <GhostButton onClick={() => del(entry.id)}><TrashIcon
                                    className={"w-5 h-5"}/>
                                </GhostButton>}

                            </DataTableCell>
                        </DataTableRow>
                    })}
                </DataTable>
                <div className="flex">
                    <div className="ml-auto">
                        <Paginator controller={controller}/>
                    </div>
                </div>
            </div>
            {taximeterModalOpen && <TaximeterModal
                taximeterId={selectedTaximeterId}
                onClose={() => {
                    setTaximeterModalOpen(false);
                    setSelectedTaximeter(undefined);
                    controller.fetch();
                }}
            />}
        </AppLayout>
};