import {Trip} from "../../models/Trip";
import React, {useEffect, useState} from "react";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "../ui/Modal";
import {GhostButton} from "../ui/Button";
import {PositionService} from "../../services/PositionService";
import {MapContainer, Marker, Polyline, Popup, TileLayer, useMap} from 'react-leaflet'
import {Position} from "../../models/Position";
import {Icon, LatLng} from "leaflet";
import {Utils} from "../../helper/Utils";



interface Props {
    onClose: () => void;
    trip: Trip;
}

const CenteredPolyline: React.FC<{positions: Position[]}> = (props) => {

    const positions = props.positions;

    const leafletPositions = positions.map(position => new LatLng(position.lat, position.long));

    const map = useMap();

    return <>
        <Polyline
            positions={leafletPositions}
            weight={3}
            opacity={0.5}
            color="blue"
            smoothFactor={1}
            ref={ref => {
                if (ref) {
                    map.invalidateSize();
                    map.fitBounds(ref.getBounds(), {padding: [15, 15]});
                }
            }}
        />
        <Marker position={leafletPositions[0]} icon={new Icon({
            iconUrl: require('../../images/marker_start.png'),
            iconSize: [35, 45],
            iconAnchor: [17, 42],
            popupAnchor: [1, -34],
        })}>
            <Popup>
                <span>Start</span>
            </Popup>
        </Marker>
        <Marker position={leafletPositions[leafletPositions.length-1]} icon={new Icon({
            iconUrl: require('../../images/marker_end.png'),
            iconSize: [35, 45],
            iconAnchor: [17, 42],
            popupAnchor: [1, -34],
        })}>
            <Popup>
                <span>Ende</span>
            </Popup>
        </Marker>
    </>
}

export const TripMapModal: React.FC<Props> = (props) => {

    const [positions, setPositions] = useState<Position[]>([]);

    useEffect(() => {
        (async () => {
            const _positions = await new PositionService().getByTrip(props.trip.id);
            setPositions(_positions);
        })();
    }, [props.trip])


    return <Modal size={"xl"}>
        <ModalHeader>Fahrtkarte</ModalHeader>
        <ModalBody>
            <MapContainer zoom={14} minZoom={6} maxZoom={17} style={{height: 500}} center={Utils.defaultMapCenter}>
                <TileLayer
                    attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                    url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                />
                {positions.length > 0 && <CenteredPolyline positions={positions} />}
            </MapContainer>
        </ModalBody>
        <ModalFooter>
            <GhostButton onClick={() => props.onClose()}>Schließen</GhostButton>
        </ModalFooter>
    </Modal>
}