import {LatLng} from "leaflet";


export class Utils {
    static precisionRound(value: number, decimals: number) {
        let tenToN = 10 ** decimals
        return Math.round(value * tenToN) / tenToN;
    }

    static defaultMapCenter = new LatLng(47.3769, 8.5417);
}