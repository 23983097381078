import React from "react";
import {PolymorphicComponent} from "../../types/PolymorphicComponent";
import classNames from "classnames";

interface BaseProps {
    size?: "md" | "xl";
    className?: string;
}

export type ContainerProps<T extends React.ElementType = "div"> = PolymorphicComponent<BaseProps, T>

export function Container<T extends React.ElementType = "div">(props: ContainerProps<T>) {

    const {
        size = "md",
        as: Component = "div",
        className = "",
        children,
        ...rest
    } = props;

    return <Component className={classNames({
        "sm:max-w-lg sm:mx-auto": size === "md",
        "sm:max-w-2xl sm:mx-auto": size === "xl",
        [className]: true,
    })} {...rest}>
        {children}
    </Component>
}