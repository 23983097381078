import create from "zustand";
import * as uuid from "uuid";

export interface INotification {
    id: string;
    type: "error" | "success";
    title: string;
    description?: string;
}

export const NotificationInvoker = (type: INotification["type"], title: string, description?: string) => {
    const id = uuid.v4();
    useNotificationState.getState().addNotification({
        id,
        type,
        title,
        description,
    });

    setTimeout(() => {
        useNotificationState.getState().removeNotification(id);
    }, 3000);
}

export const useNotificationState = create<{
    notifications: INotification[];
    addNotification: (notification: INotification) => any;
    removeNotification: (id: string) => any;
}>((set, get) => ({
    notifications: [],
    addNotification: (notification) => {
        set({
            notifications: [...get().notifications, notification],
        })
    },
    removeNotification: (id) => {
        set({
            notifications: get().notifications.filter(p => p.id !== id)
        });
    }
}))