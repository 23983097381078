import React from "react";
import {AsyncSelect, BaseAsyncSelectProps} from "../AsyncSelect";
import {useAsyncSelect} from "../../../hooks/useAsyncSelect";
import {Taximeter} from "../../../models/Taximeter";
import {TaximeterService} from "../../../services/TaximeterService";



export const TaximeterSelect: React.FC<BaseAsyncSelectProps> = (props) => {

    const {getOptions, getInitial} = useAsyncSelect<Taximeter>({
        getAll: (options, query) => new TaximeterService().getAll(options, query),
        getById: (id) => new TaximeterService().get(id),
        getLabel: (taximeter) => taximeter.serial,
    })

    return (
        <AsyncSelect getOptions={getOptions}
                     getInitial={getInitial}
                     {...props}
        />
    )
}