import {BaseService} from "./BaseService";
import {BearerApiClient} from "./APIClient";
import {PaginatedResponse} from "../types/PaginatedResponse";
import * as queryString from "query-string";
import {paginatedRequestQuery} from "../helper/paginatedRequest";
import {ServiceMethodOptions} from "../helper/PagingController";
import {EventLogWithTaximeter} from "../models/EventLog";

export class EventLogService extends BaseService {

    public async getAll(options: ServiceMethodOptions) {
        const client = BearerApiClient();

        const qs = queryString.stringify({
            ...paginatedRequestQuery(options),
        })

        return this.response<PaginatedResponse<EventLogWithTaximeter>>(
            client.get(this.BASE_URL + "/event-logs?" + qs, {
                signal: options.abortSignal
            }),
        )
    }

}