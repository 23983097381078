import React, {useRef} from "react";
import {yup} from "../../extension/yup";
import {User} from "../../models/User";
import {useAuth} from "../../hooks/useAuth";
import {Card} from "../ui/Card";
import {Form} from "../ui/Form";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {PrimaryButton} from "../ui/Button";
import {AppLayout} from "../layout/AppLayout";
import {CountrySelect} from "../ui/async-selects/CountrySelect";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {UserService} from "../../services/UserService";
import {CurrencySelect} from "../ui/async-selects/CurrencySelect";


const Schema = yup.object().shape({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    password: yup.string().optional(),
    phone: yup.string().optional(),
    address: yup.string().optional(),
    city: yup.string().optional(),
    country: yup.string().optional(),
});

export const ProfileSettings: React.FC = () => {

    const {user, loadUser} = useAuth();

    const payload = useRef<Partial<User>>({
        firstname: user!.firstname,
        lastname: user!.lastname,
        phone: user!.phone,
        address: user!.address,
        city: user!.city,
        country: user!.country,
        currency: user!.currency,
    });

    return <>
        <AppLayout title="Profile">
            <Card>
                <Form initialValues={payload.current}
                      enableReinitialize={true}
                      onSubmit={async (data, helpers) => {
                          if(!data.password) {
                              delete data.password;
                          }

                          try {
                              await new UserService().update(user!.id, data);
                              NotificationInvoker("success", "Einstellungen erfolgreich gespeichert");
                              loadUser();
                          } catch (e) {
                              NotificationInvoker("error", "Fehlgeschlagen", "Aktion konnte nicht durchgeführt werden");
                          }
                      }}
                      validationSchema={Schema}
                >
                    <div className="space-y-6">
                        <div className="grid md:grid-cols-2 gap-4">
                            <FormGroup name={"firstname"} title={"Vorname"}>
                                <FormInput name={"firstname"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"lastname"} title={"Nachname"}>
                                <FormInput name={"lastname"} type={"text"} />
                            </FormGroup>
                        </div>

                        <FormGroup name={"password"} title={"Passwort"} description={"Geben Sie ein neues Passwort ein, wenn Sie Ihr Passwort ändern wollen."}>
                            <FormInput name={"password"} type={"password"} />
                        </FormGroup>

                        <FormGroup name={"phone"} title={"Telefon"}>
                            <FormInput name={"phone"} type={"text"} />
                        </FormGroup>

                        <div className="grid md:grid-cols-3 gap-4">
                            <FormGroup name={"address"} title={"Addresse"}>
                                <FormInput name={"address"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"city"} title={"Stadt"}>
                                <FormInput name={"city"} type={"text"} />
                            </FormGroup>

                            <FormGroup name={"country"} title={"Land"}>
                                <CountrySelect name={"country"} />
                            </FormGroup>
                        </div>

                        <FormGroup name={"currency"} title={"Währung"}>
                            <CurrencySelect name={"currency"} />
                        </FormGroup>

                        <PrimaryButton size={"lg"} type={"submit"}>
                            Speichern
                        </PrimaryButton>
                    </div>
                </Form>
            </Card>
        </AppLayout>
    </>
}