import * as React from "react";


interface DataTableRowProps extends React.HTMLAttributes<HTMLDivElement> {
    icon?: React.ReactElement
}

export const DataTableRow: React.FC<DataTableRowProps> = ({
                                                              className = '',
                                                              icon,
                                                              children,
                                                              ...rest
                                                          }) => {
    return <tr className={"w-full data-table-row " + className} {...rest}>
        {icon && <td className={"data-table-icon"}>{icon}</td>}
        {children}
    </tr>
}

export const DataTableHeader: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
                                                                                    className = '',
                                                                                    ...rest
                                                                                }) => {
    return <DataTableRow className={" data-table-header " + className} {...rest} />
}
