import React from "react";
import {useField, useFormikContext} from "formik";
import Select from "react-select";
import classNames from "classnames";

export type TSelectOptionValue = string | number | undefined;

export interface SelectOption {
    label: string;
    value: string | number | undefined;
    data?: any;
}

export interface FormSelectBaseProps {
    name: string;
    onChange?: (value: string | number | undefined | null) => void;
    options: SelectOption[];
    filter?: (option: SelectOption) => boolean;
}

export const FormSelect: React.FC<FormSelectBaseProps> = (props) => {

    const [field, meta] = useField(props.name);
    const {setFieldValue} = useFormikContext();

    const options = props.options;
    const value = options.find(option => option.value === field.value);

    const filter = (option: SelectOption) => {
        return props.filter?.(option) ?? true;
    }

    return <Select
        onChange={change => {
            setFieldValue(props.name, change?.value, true);
            props.onChange?.(change?.value);
        }}
        className={classNames({
            "form-select-container": true,
            "has-error": meta.error,
        })}
        classNamePrefix={"form-select"}
        options={props.options}
        value={value}
        placeholder={"Select"}
        isClearable={true}
        filterOption={item => filter(item)}
    />
}