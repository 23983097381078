import {Navigate, Route, Routes} from "react-router-dom";
import {Dashboard} from "./pages/Dashboard";
import {Login} from "./auth/Login";
import React, {useEffect} from "react";
import {Companies} from "./pages/Companies";
import {Logout} from "./auth/Logout";
import {CompanyDetail} from "./pages/CompanyDetail";
import {Users} from "./pages/Users";
import {UsersDetail} from "./pages/UsersDetail";
import {Vehicles} from "./pages/Vehicles";
import {VehiclesDetail} from "./pages/VehiclesDetail";
import {DataBrowser} from "./pages/DataBrowser";
import {Map} from "./pages/Map";
import {ProfileSettings} from "./pages/ProfileSettings";
import {AuthGuard} from "./auth/guards/AuthGuard";
import {ExpensesPage} from "./pages/ExpensesPage";
import {Drivers} from "./pages/Drivers";
import {DriversDetail} from "./pages/DriversDetail";
import {PasswordReset} from "./auth/PasswordReset";
import {PasswordResetSetPassword} from "./auth/PasswordResetSetPassword";
import {isSmallDevice} from "../isSmallDevice";
import {useRouter} from "../hooks/useRouter";
import {useAppState} from "../hooks/useAppState";


export const Router = () => {
    const router = useRouter()
    const {setSidebarOpen} = useAppState()
    console.log(router.location.pathname)
    useEffect(() => {
        console.log(isSmallDevice)
        if (isSmallDevice) {
            console.log("set false");
            setSidebarOpen(false);
        }
    }, [router.location.pathname, setSidebarOpen]);


    return <Routes>
        <Route path="/" element={
            <AuthGuard>
                <Dashboard/>
            </AuthGuard>
        }/>
        <Route path="/companies" element={
            <AuthGuard>
                <Companies/>
            </AuthGuard>
        }/>

        <Route path="/companies/:id" element={
            <AuthGuard>
                <CompanyDetail/>
            </AuthGuard>
        }/>

        <Route path="/users" element={
            <AuthGuard>
                <Users/>
            </AuthGuard>
        }/>
        <Route path="/users/:id" element={
            <AuthGuard>
                <UsersDetail/>
            </AuthGuard>
        }/>

        <Route path="/drivers" element={
            <AuthGuard>
                <Drivers/>
            </AuthGuard>
        } />

        <Route path={"/drivers/:id"} element={
            <AuthGuard>
                <DriversDetail />
            </AuthGuard>
        } />

        <Route path="/vehicles" element={
            <AuthGuard>
                <Vehicles/>
            </AuthGuard>
        }/>
        <Route path="/vehicles/:id" element={
            <AuthGuard>
                <VehiclesDetail/>
            </AuthGuard>
        }/>

        <Route path="/data-browser" element={
            <AuthGuard>
                <DataBrowser/>
            </AuthGuard>
        }/>
        <Route path="/map" element={
            <AuthGuard>
                <Map/>
            </AuthGuard>
        }/>

        <Route path="/user/profile" element={
            <AuthGuard>
                <ProfileSettings/>
            </AuthGuard>
        }/>

        <Route path="/expenses" element={
            <AuthGuard>
                <ExpensesPage />
            </AuthGuard>
        }/>

        <Route path="/login" element={<Login/>}/>
        <Route path="/password-reset" element={<PasswordReset />} />
        <Route path="/password-reset/:token" element={<PasswordResetSetPassword />} />
        <Route path="/logout" element={
            <AuthGuard>
                <Logout/>
            </AuthGuard>
        }/>

        <Route path="*" element={<Navigate to="/" replace/>}/>
    </Routes>
}