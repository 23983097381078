import * as uuid from "uuid";

export default class TypeRequestManager {

    private currentRequestId: null | string = null;

    public request() : string {
        this.currentRequestId = uuid.v4();
        return this.currentRequestId!;
    }

    public isValid(requestId : string): boolean {
        return requestId === this.currentRequestId;
    }

    private controller = new AbortController();

    public get signal() {
        return this.controller.signal;
    }

    public abort() {
        this.controller.abort();
        this.controller = new AbortController();
    }
}