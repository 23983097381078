import React, {useRef, useState} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {TableActions} from "../ui/TableActions";
import {usePagingController} from "../../hooks/usePagingController";
import {BanIcon, LockOpenIcon, PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import Paginator from "../ui/Paginator";
import {SearchForm} from "../ui/concrete/SearchForm";
import {UserService} from "../../services/UserService";
import {Badge} from "../ui/Badge";
import {useAuth} from "../../hooks/useAuth";
import {PromptConfirm} from "../../hooks/usePromptState";
import {TypeId} from "../../types/TypeId";
import {NotificationInvoker} from "../../hooks/useNotificationState";


interface Props {

}

export const Users: React.FC<Props> = () => {

    const filter = useRef<{ search: string | undefined }>({
        search: undefined,
    });

    const {controller} = usePagingController({
        perPage: 25,
        serviceMethod: (options) => {
            return new UserService().getAll(options, {
                search: filter.current.search,
            })
        }
    });

    const del = async (id: TypeId) => {
        const confirm = await PromptConfirm("Sind Sie sicher?", "Dass Sie diesen Benutzer löschen möchten? Diese Aktion kann nicht mehr rückgängig gemacht werden.");
        if (!confirm) return;

        await new UserService().delete(id);
        NotificationInvoker("success", "Benutzer gelöscht");
        controller.fetch()
    }

    const lock = async (id: TypeId) => {
        const confirm = await PromptConfirm("Sind Sie sicher?", "Dass Sie diesen Benutzer sperren möchten?");
        if (!confirm) return;

        await new UserService().update(id, {
            locked: true,
        });
        NotificationInvoker("success", "Bentuzer gesperrt");
        controller.fetch();
    }

    const unlock = async (id: TypeId) => {
        await new UserService().update(id, {
            locked: false,
        });
        NotificationInvoker("success", "Benutzer entsperrt");
        controller.fetch();
    }

    const {permissions} = useAuth();

    return <AppLayout title={"Benutzer"}>
            <div className="space-y-6">
                <TableActions>
                    <div className="w-full">
                        <div className="flex-col md:flex-row flex gap-2">
                            <div className="w-full md:w-4/12">
                                <SearchForm loading={controller.loading} onChange={(search) => {
                                    filter.current.search = search;
                                    controller.resetAndFetch();
                                }}
                                />
                            </div>
                            <div className="ml-auto" />
                            {permissions?.users.create &&
                                <PrimaryButton as={Link} to={"/users/new"}>Erstellen</PrimaryButton>
                            }
                        </div>
                    </div>
                </TableActions>

                <DataTable>
                    <DataTableHeader>
                        <DataTableCell>
                            Name
                        </DataTableCell>
                        <DataTableCell>
                            E-Mail
                        </DataTableCell>
                        <DataTableCell>
                            Benutzertyp
                        </DataTableCell>
                        <DataTableCell size={2}>

                        </DataTableCell>
                    </DataTableHeader>
                    {controller.data.map(entry => {
                        return <DataTableRow key={entry.id}>
                            <DataTableCell>
                                {entry.firstname} {entry.lastname}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.email}
                            </DataTableCell>
                            <DataTableCell>
                                <Badge fill={"blue"}>{entry.type}</Badge>
                            </DataTableCell>
                            <DataTableCell className={"text-right"}>

                                {entry.__abilities.update &&  <GhostButton as={Link} to={"/users/" + entry.id}>
                                    <PencilIcon className={"w-5 h-5"}/>
                                </GhostButton>}

                                {entry.__abilities.ban && <GhostButton onClick={() => entry.locked ? unlock(entry.id) : lock(entry.id)}>
                                        {entry.locked
                                            ? <LockOpenIcon className={"w-5 h-5"}/>
                                            : <BanIcon className={"w-5 h-5"}/>
                                        }
                                </GhostButton>}

                                {entry.__abilities.delete && <GhostButton onClick={() => del(entry.id)}><TrashIcon
                                    className={"w-5 h-5"}/>
                                </GhostButton>}

                            </DataTableCell>
                        </DataTableRow>
                    })}
                </DataTable>
                <div className="flex">
                    <div className="ml-auto">
                        <Paginator controller={controller}/>
                    </div>
                </div>
            </div>
        </AppLayout>
};