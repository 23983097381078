import {GhostButton, PrimaryButton} from "../ui/Button";
import {Card} from "../ui/Card";
import {Container} from "../ui/Container";
import {FormGroup} from "../ui/FormGroup";
import {FormInput} from "../ui/FormInput";
import {Form} from "../ui/Form";
import {AuthService} from "../../services/AuthService";
import {useLoading} from "../../hooks/useLoading";
import {GuestGuard} from "./guards/GuestGuard";
import {NotificationInvoker} from "../../hooks/useNotificationState";
import {NotificationManager} from "../ui/NotificationManager";
import React, {useState} from "react";
import {yup} from "../../extension/yup";

const Schema = yup.object().shape({
    email: yup.string().required().email(),
});

export const PasswordReset = () => {

    const {loading, setLoading} = useLoading();
    const [successful, setSuccessful] = useState(false);

    const passwordReset = async (data: { email: string }) => {
        setLoading(true);
        try {
            await new AuthService().passwordReset(data.email);
            setSuccessful(true);
        } catch (e) {
            NotificationInvoker("error", "Login fehlgeschlagen", "Bitte überprüfen Sie Ihre Daten");
        }
        setLoading(false);
    }

    return <GuestGuard>
        <div className={"w-screen h-screen bg-gray-100 flex"}>
            <Container size={"md"} className={"my-auto w-full"}>
                <p className={"text-xl font-semibold text-center text-gray-500 mb-6"}>taximeter.ch</p>
                <Card>
                    {!successful && (
                        <Form initialValues={{email: "", password: ""}}
                              validationSchema={Schema}
                              onSubmit={(data) => {
                                  passwordReset(data);
                              }}
                        >
                            <div className="space-y-6">
                                <FormGroup name={"email"} title={"E-Mail"}
                                           description={"Bitte geben Sie Ihre E-Mail ein"}>
                                    <FormInput type="text" name={"email"}/>
                                </FormGroup>
                                <div className="space-x-3">
                                    <PrimaryButton as={"button"} type={"submit"} loading={loading}>Passwort zurücksetzen</PrimaryButton>
                                    <GhostButton as={"a"} href={"/login"}>Login</GhostButton>
                                </div>
                            </div>
                        </Form>
                    )}
                    {successful && (
                        <p>Wir haben dir eine E-Mail mit Anweisungen gesendet um dein Passwort zurück zu setzen.</p>
                    )}
                </Card>
            </Container>
        </div>
        <NotificationManager/>
    </GuestGuard>
};