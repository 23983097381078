import {Prompt, usePromptState} from "../../hooks/usePromptState";
import {Modal, ModalBody, ModalFooter, ModalHeader} from "./Modal";
import {GhostButton, PrimaryButton} from "./Button";
import React, {useEffect} from "react";

const PromptModal: React.FC<{prompt: Prompt}> = (props) => {
    const {prompt} = props;

    useEffect(() => {
        const listener = (event: KeyboardEvent) => {
            if (event.key.toLowerCase() === "enter") {
                prompt.onConfirm();
                event.preventDefault();
                event.stopPropagation();
            }

            if (event.key.toLowerCase() === "escape") {
                prompt.onCancel();
                event.preventDefault();
                event.stopPropagation();
            }
        };

        window.addEventListener("keydown", listener);
        return () => {
            window.removeEventListener("keydown", listener);
        }
    }, [prompt]);

    return <>
        <Modal>
            <ModalHeader>
                {prompt.title}
            </ModalHeader>
            <ModalBody>
                <p>{prompt.description}</p>
            </ModalBody>
            <ModalFooter>
                <GhostButton onClick={() => prompt.onCancel()}>Cancel</GhostButton>
                <PrimaryButton onClick={() => prompt.onConfirm()}>Confirm</PrimaryButton>
            </ModalFooter>
        </Modal>
    </>
}

export const PromptManager = () => {
    const {prompts} = usePromptState();

    return <>
        {prompts.map(prompt => {
            return <PromptModal prompt={prompt} />
        })}
    </>
};