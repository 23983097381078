import React, {useRef} from "react";
import {AppLayout} from "../layout/AppLayout";
import {DataTable} from "../ui/data-table/DataTable";
import {DataTableHeader, DataTableRow} from "../ui/data-table/DataTableRow";
import {DataTableCell} from "../ui/data-table/DataTableCell";
import {GhostButton, PrimaryButton} from "../ui/Button";
import {TableActions} from "../ui/TableActions";
import {usePagingController} from "../../hooks/usePagingController";
import {CompanyService} from "../../services/CompanyService";
import {PencilIcon, TrashIcon} from "@heroicons/react/outline";
import {Link} from "react-router-dom";
import {PromptConfirm} from "../../hooks/usePromptState";
import Paginator from "../ui/Paginator";
import {SearchForm} from "../ui/concrete/SearchForm";
import {NotificationInvoker} from "../../hooks/useNotificationState";


interface Props {

}

export const Companies: React.FC<Props> = () => {

    const filter = useRef<{search: string | undefined}>({
        search: undefined,
    })

    const {controller} = usePagingController({
        perPage: 25,
        serviceMethod: (options) => {
            return new CompanyService().getAll(options, {
                search: filter.current.search,
            })
        }
    });

    const del = async (id: number) => {
        const res = await PromptConfirm("Sind Sie sicher?", "Dass Sie dieses Unternehmen löschen möchten? Diese Aktion kann nicht mehr rückgängig gemacht werden.");
        if (!res) {
            return;
        }

        await new CompanyService().delete(id);
        NotificationInvoker("success", "Erfolgreich gelöscht");
        controller.resetAndFetch();
    }

    return <AppLayout title={"Unternehmen"}>
            <div className="space-y-6">
                <TableActions>
                    <div className="w-full">
                        <div className="flex-col md:flex-row flex gap-2">
                            <div className="w-full md:w-4/12">
                                <SearchForm loading={controller.loading} onChange={(search) => {
                                    filter.current.search = search;
                                    controller.resetAndFetch();
                                }}
                                />
                            </div>
                            <PrimaryButton as={Link} to={"/companies/new"} className={"ml-auto"}>Erstellen</PrimaryButton>
                        </div>
                    </div>
                </TableActions>

                <DataTable>
                    <DataTableHeader>
                        <DataTableCell size={4}>
                            Unternehmen
                        </DataTableCell>
                        <DataTableCell>
                            E-Mail
                        </DataTableCell>
                        <DataTableCell size={2}>

                        </DataTableCell>
                    </DataTableHeader>
                    {controller.data.map(entry => {
                        return <DataTableRow key={entry.id}>
                            <DataTableCell>
                                {entry.name}
                            </DataTableCell>
                            <DataTableCell>
                                {entry.email}
                            </DataTableCell>
                            <DataTableCell className={"text-right"}>
                                {entry.__abilities.update && <GhostButton as={Link} to={"/companies/" + entry.id}><PencilIcon className={"w-5 h-5"}/></GhostButton>}
                                {entry.__abilities.delete && <GhostButton onClick={() => del(entry.id)}><TrashIcon className={"w-5 h-5"}/></GhostButton>}
                            </DataTableCell>
                        </DataTableRow>
                    })}
                </DataTable>
                <div className="flex">
                    <div className="ml-auto">
                        <Paginator controller={controller} />
                    </div>
                </div>
            </div>
        </AppLayout>
};