import React from "react";
import {PolymorphicComponent} from "../../types/PolymorphicComponent";
import {useField} from "formik";
import classNames from "classnames";

interface BaseProps {
    name: string;
}

type Props<T extends React.ElementType = "input"> = PolymorphicComponent<BaseProps, T>

export function FormInput<T extends React.ElementType = "input">(props: Props<T>) {

    const {
        as: Component = "input",
        className = "",
        ...rest
    } = props;

    const [field, meta] = useField(props.name);

    return <Component className={classNames({
        "block w-full border border-gray-200 text-gray-500 rounded-md px-3 py-2 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50": true,
        [className]: true,
        "!border-red-400 focus:!ring-red-200 focus:!ring-opacity-50 !bg-red-50": meta.error
    })} {...field} {...rest} />
}