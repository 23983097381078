import React from "react";
import {Sidebar} from "./Sidebar";
import {SecondaryButton} from "../ui/Button";
import {MenuIcon} from "@heroicons/react/outline";
import {useAppState} from "../../hooks/useAppState";
import {PromptManager} from "../ui/PromptManager";
import {NotificationManager} from "../ui/NotificationManager";
import classNames from "classnames";
import {useAuth} from "../../hooks/useAuth";
import {Dropdown, DropdownContent, DropdownItem} from "../ui/Dropdown";
import {Link} from "react-router-dom";


interface Props {
    title: string;
    full?: boolean;
}

export const AppLayout: React.FC<Props> = ({title, children, full = false}) => {

    const {sidebarOpen, setSidebarOpen} = useAppState();
    const {user} = useAuth();

    return <div className={"w-screen h-screen flex overflow-auto"}>
        <Sidebar open={sidebarOpen}/>
        <div className="w-full flex flex-col">
            <div
                className={"relative z-50 py-4 px-6 sm:px-6 lg:px-8 flex flex-row items-center sticky flex-shrink-0 border-b border-b-gray-200 bg-white shadow shadow-gray-200 h-24"}>
                <SecondaryButton size={"sm"} className={"mr-6"} onClick={() => setSidebarOpen(!sidebarOpen)}>
                    <MenuIcon className={"w-5 h-5"}/>
                </SecondaryButton>
                <div className={"font-semibold text-xl text-gray-800 leading-tight"}>{title}</div>
                <div className="ml-auto">
                    <Dropdown>
                        {({isOpen, toggle}) => (
                            <>
                                <button className={"flex text-sm border-2 border-transparent rounded-full focus:outline-none focus:border-gray-300 transition"}
                                    onClick={() => toggle()}>
                                    <img className={"w-8 h-8 rounded-full"} src={`https://ui-avatars.com/api/?name=${user!.firstname}+${user!.lastname}&color=7F9CF5&background=EBF4FF`} alt={""}/>
                                </button>
                                {isOpen && <DropdownContent>
                                    <Link to={"/user/profile"}>
                                        <DropdownItem>
                                            Einstellungen
                                        </DropdownItem>
                                    </Link>
                                    <Link to={"/logout"}>
                                        <DropdownItem>
                                            Abmleden
                                        </DropdownItem>
                                    </Link>
                                </DropdownContent>}
                            </>
                        )}
                    </Dropdown>
                </div>
            </div>
            <div className={"h-full bg-gray-50 overflow-auto"}>
                <main className={classNames({
                    "h-full": full,
                })}>
                    <div className={classNames({
                        "mx-auto": true,
                        "h-full": full,
                        "py-10 px-6 sm:px-6 lg:px-8": !full,
                    })}>
                        {children}
                    </div>
                </main>
            </div>
        </div>
        <PromptManager />
        <NotificationManager/>
    </div>
}