import {TypeId} from "../types/TypeId";
import {PositionWithDriver} from "./Position";
import {WithAbilities} from "../types/WithAbilities";

export enum TaximeterStatus {
    HIRED = "HIRED",
    CLOSED = "CLOSED",
    FREE = "FREE",
}

export interface Taximeter extends WithAbilities<"update"> {
    id: number;
    serial: string;
    model: string;
    company_id: TypeId;
    status: TaximeterStatus;
}

export const taximeterStatusToColor = (status: TaximeterStatus): "green" | "red" | "blue" => {
    return {
        [TaximeterStatus.FREE]: "green",
        [TaximeterStatus.HIRED]: "red",
        [TaximeterStatus.CLOSED]: "blue",
    }[status] as any;
}

export interface TaximeterWithPosition extends Taximeter {
    latest_position: PositionWithDriver;
}